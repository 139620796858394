import styles from "./Popup.module.scss";
import close from "../../../../../assets/images/for-pages/icons/closeDialog.png";
import shakelIcon from "../../../../../assets/images/for-pages/icons/shakel.png";
import download from "../../../../../assets/images/for-pages/icons/getDocument.png";
import dayjs from "dayjs";
import he from 'dayjs/locale/he'; // load on demand
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import SignatureCanvas from 'react-signature-canvas'
import {useState} from "react";
import { useRef } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { setErr } from "../../../../store/forAll/errSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import classNames from "classnames";
//import useAuth from "../../../../hooks/useAuth";
//import axios from "axios";

function Popup(props) {
  const _D = "DESKTOP";
  const _M = "MOBILE";
 // const { auth } = useAuth();
//  let currentRole = auth.roles[0];
 // const [phoneNumber, setphoneNumber] = useState('');
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  dayjs.locale(he);
  dayjs.extend(localizedFormat)
  const {isOpen, setIsOpen, documentId, isRead,setopenPayment, isThreeSteps, sendAnalitics3} = props;
  const [tab, setTab] = useState(1);
  const [isChecked, setIsChecked] = useState(false)
  const [allInformation, setAllInformation] = useState({})
  const canvasRef = useRef(null)

  function dataURLtoFile(dataurl, filename = "file") {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
}

  const getWidthOfWindow = () => {
    return (window.innerWidth || 961)
  }

  const getMobileOrDesctop = () => {
    return ((getWidthOfWindow() > 960) ? _D : _M)
  }

  // useEffect(() => {
  //   const fetchData = async () => {
  //   const result = currentRole === 'USER' ? await axios.get(`${process.env.REACT_APP_API_PATH}main/user/phone?id=${allInformation.userId}`) :
  //     await axios.get(`${process.env.REACT_APP_API_PATH}main/sup/phone?id=${allInformation.supplierId}`);
  //     setphoneNumber('972' + result.data.phoneNumber.split('0')[1]);
  //   };
  //   fetchData();
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const saveSignature = async(image) => {
    if(canvasRef.current.isEmpty() || !isChecked)return
    try {
      
      const formData = new FormData();
      formData.append("file", dataURLtoFile(canvasRef.current.toDataURL()));
      // console.log('formData',canvasRef);
      const res = await axiosPrivate.post(`/contract/signature?contractId=${documentId}&type=${getMobileOrDesctop()}`,formData,{
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
      //  sendZapierUser();
        // console.log(res);
      }
      setIsOpen(false)
      if(setopenPayment){
        sendAnalitics3(documentId)
        setopenPayment()
      }
    } catch (error) {
      // console.log(error)
    }
    // console.log(canvasRef.current.isEmpty(), canvasRef.current.toDataURL())
  }

  // const sendZapierUser = () => {
  //   const body = `מזל טוב! סגרת ספק לאירוע שלך
  //   אנחנו כל כך שמחים לראות את האירוע שלך מתמלא בספקים טובים, איכותיים ומומלצים.
  //   פרטי הספק שנסגר לאירוע: ${allInformation.eventTypeId}
  //   התקדמת עוד צעד אל עבר האירוע המושלם.
  //   לצט מול הספק וצפייה בחוזה החתום לחץ על הלינק ${window.location.href}`
  //   const zapierUrl = 'https://hooks.zapier.com/hooks/catch/14382427/bvsvnrq/';
  //   axios.get(`${zapierUrl}?from=${'97233762625'}&to=${phoneNumber}&body=${encodeURIComponent(body)}`)
  //     .then((response) => {
  //      // console.log('Zapier request sent successfully:', response.data);
  //     })
  //     .catch((error) => {
  //     //  console.error('Error sending Zapier request:', error);
  //     });
  // }

  // {
  //   "id": 0,
  //   "supplierId": 0,
  //   "supplierName": "string",
  //   "categoryId": 0,
  //   "categoryName": "string",
  //   "creationDate": "2023-02-09T14:34:41.561Z",
  //   "services": [
  //     {
  //       "serviceId": 0,
  //       "serviceName": "string",
  //       "price": 0
  //     }
  //   ],
  //   "totalAmount": 0,
  //   "discountPrice": 0,
  //   "prepayment": 0
  // }

  const getAllData = async() => {
    // console.log(isOpen, documentId, isRead)
    try {
      const res = await axiosPrivate.get(`/contract/details?contractId=${documentId}`);
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        const data = await res.data;
        setAllInformation(data)
      }
      
    } catch (error) {
      // console.log(error)
    }
  }
  const getPdf = async() => {
    try {
    let secondResponse = null
    const res = await axiosPrivate.get(`/contract/document/request?contractId=${documentId}`,{responseType: 'blob'});
  
    const data = await res.data;
 
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.pdf");
        document.body.appendChild(link);
        link.click();
        if(allInformation.supplierDocument){
          secondResponse = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}contract/supplier/document?contractId=${documentId}`,{responseType: 'blob'});
          const dataSecond = await secondResponse.data;
          const url2 = window.URL.createObjectURL(new Blob([dataSecond]));
          const link2 = document.createElement("a");
          link2.href = url2;
          link2.setAttribute("download", "file2.pdf");
          document.body.appendChild(link2);
          link2.click()
        }
      }   
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if(!documentId)return
    getAllData();
    // console.log(allInformation)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[documentId])


  return (
    <>
      {(isOpen && documentId) && (
        <div className={styles.dialogWrapper}>
          <div className={styles.mainDialog}>
            <div className={styles.closeDialog}>
              <img
                onClick={() => {
                  setIsOpen(!isOpen);
                  setTab(1)
                }}
                alt="closeImage"
                src={close}
              />
            </div>
            <div className={styles.content}>
              { tab === 1 ? 
              <div className={styles.tab1}>
                <div className={styles.tab1Title}>{allInformation.supplierName}</div>
                <div className={styles.tab1SubTitle}>{allInformation.categoryName}</div>
                <div className={styles.tab1Underline1}></div>
                {isRead ?
                
                <div className={styles.tab1Title1}>
                  {"השירות:"}
                </div>:
                <>
                  <div className={classNames(styles.tab1Title1, styles.desctop1)}>
                    {"הצעת מחיר עדכנית‌‌ לתאריך "}{dayjs(allInformation.creationDate).format("L")}
                  </div>
                  <div className={classNames(styles.tab1Title1, styles.mobile)}>
                    {"השירות:"}
                  </div>
                </>
                
                }
                
                
                
                <div className={styles.tab1ContNumbers}>
                {
                  allInformation?.services?.map((service, index) => {
                    return(
                      <div className={styles.div1} key={index}>{service.serviceName}{" "}{"-"}{" "}{service.price}</div>
                    )
                  })
                }
                </div>
                
                <div className={styles.tab1Underline2}></div>
                <div style={{display:"flex", alignItems:"flex-end"}} className={styles.tab1Cost1}>
                  {"‌סך הכל "}{"- "}{allInformation.totalAmount}
                  <img src={shakelIcon} alt="cost" />
                  </div>
                {allInformation.totalAmount !== allInformation.discountPrice && <div style={{display:"flex", alignItems:"flex-end"}} className={styles.tab1Cost2}>
                  {"‌מחיר לאחר הנחה"}{" - "}{allInformation.discountPrice}
                  <img src={shakelIcon} alt="cost" />
                </div>}
                <div className={styles.tab1CostTotal}>
                  <div className={styles.tab1CostTotalTitle}>
                    <span>{"- "}{"סך תשלום מקדמה"}{" "}{allInformation.prepayment} </span>
                    <img src={shakelIcon} alt="cost" />
                  </div>
                  <div className={styles.tab1CostTotalDescr}>
                    {allInformation.prepaymentPercentage || "10"}{"% "}{"מכלל העסקה"}
                  </div>
                </div>
                {!isRead && <><div className={styles.buttons}>
                    <div onClick={() => setTab(2)}  className={styles.save} 
                      >
                      {"המשך לחתימה"}
                    </div>
                </div>
                <div className={styles.tabProgress}>
                  {isThreeSteps && <span style={{borderColor:"#405F6A"}}></span>}
                  <span style={{borderColor:"#405F6A"}}></span>
                  <span style={{borderColor:"rgba(112, 112, 112, .17)"}}></span>
                </div></>}
              </div>
              :
              <div className={styles.tab2}>
                <div className={styles.tab2Title}>{"נספח לחוזה"}</div>
                {allInformation.description.length > 0 && <div className={styles.tab2Text}>
                  {allInformation.description}
                </div>}
                <div className={styles.checkbox}>
                  <input 
                    onClick={() => {
                      setIsChecked(true)
                    }}
                    id="isEmailAccept" type="radio"  />
                  <label htmlFor="isEmailAccept">
                    {"עליך לאשר את חוזה הספק, לפני שתחתום ותאשר את העסקה"}
                  </label>

                  { !isChecked && 
                    <p className={styles.errorMessage}>
                      שדה חובה
                    </p>
                  }
                  
                </div>
                <div className={styles.download}>
                  <img onClick={() => getPdf()} src={download} alt="download" />
                  <span onClick={() => getPdf()} className={styles.downloadLink}>{"הורדת החוזה"}</span>
                </div>
                <div className={styles.signa}>
                  <span>
                    {"חתימה דיגיטלית"}
                  </span>

                  { (getWidthOfWindow() > 960) ? <div className={classNames(styles.signaContent,styles.desctop1)}>
                    <SignatureCanvas 
                    ref={canvasRef}
                    canvasProps={{width: 700, height: 100, className: 'sigCanvas'}} />
                  </div>
                  :
                  <div className={classNames(styles.signaContent,styles.mobile)}>
                    <SignatureCanvas 
                    ref={canvasRef}
                    canvasProps={{width: 310, height: 135, className: 'sigCanvas'}} />
                  </div>}
                </div>
                <div className={styles.buttons}>
                    <div  className={styles.save} 
                      onClick={() => {
                        saveSignature()
                      }}
                      >
                      {"אישור חתימה"}
                    </div>
                </div>
                <div className={styles.tabProgress}>
                  {isThreeSteps && <span style={{borderColor:"#405F6A"}}></span>}
                  <span style={{borderColor:"#405F6A"}}></span>
                  <span style={{borderColor:"#405F6A"}}></span>
                </div>
              </div>
              
              }
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default Popup;
