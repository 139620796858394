import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { TEMP_USER_KEY } from '../consts/local_keys';

export const fetchAllEvetns = createAsyncThunk(
  'main/fetchAllEvetns',
  async function (_, { rejectWithValue }) {
    try {
      const response = await axios.get(`/eventType`);
      // console.log(response)
      if (response.status !== 200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const fetchAllServices = createAsyncThunk(
  'main/fetchAllServices',
  async function (payload, { rejectWithValue }) {
    try {
      const response = await axios.get(`/venue/filter/name?name=${payload.name}`);
      // console.log(response);
      if (response.status !== 200) {
        throw new Error('Server Error');
      }
      return response.data.content;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAllEvetnsHomePage = createAsyncThunk(
  'main/fetchAllEvetnsHomePage',
  async function ({ role, axiosPrivate }, { rejectWithValue }) {
    try {
      let response;
      if (role === '') {
        response = await axios.get(
          `/eventType/checkList?userToken=${localStorage.getItem(TEMP_USER_KEY)}`
        );
      } else {
        response = await axiosPrivate.get(`/eventType/checkList`);
      }
      if (response.status !== 200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendContact = createAsyncThunk(
  'main/sendContact',
  async function (body, { rejectWithValue }) {
    // console.log(data)
    try {
      const response = await axios.post(`/main/have/question`, body);
      // console.log(response)
      if (response.status !== 200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      //console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAllRecommended = createAsyncThunk(
  'main/fetchAllRecommended',
  async function (_, { rejectWithValue }) {
    try {
      const response = await axios.get(`/main/recommended`);
      // console.log(response)
      if (response.status !== 200) {
        throw new Error('Server Error');
      }
      const data = await response.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const mainSlice = createSlice({
  name: 'main',
  initialState: {
    currentStep: 0,
    codeModalIsOpen: false,
    currentCategory: '',
    allServices: [],
    currentEvent: { id: 0, name: '' },
    currentSupplierEvent: { id: 0, name: '' },
    currentEventForChecklist: { id: 0, name: '' },
    currentPlace: { id: 0, name: '' },
    isNeedPlace: false,
    isHavePlace: false,
    allEvents: [],
    error: null,
    loadingStatus: null,
    allRecommended: [],
    isOpenUserReg: false,
    isOpenUserAuth: false,
    isOpenSupplierAuth: false,
    isOpenMobileAuth:false,
    isGoogle: false,
  },
  reducers: {
    setIsOpenUserReg(state, action) {
      state.isOpenUserReg = action.payload;
    },
    setIsOpenUserAuth(state, action) {
      state.isOpenUserAuth = action.payload;
    },
    setIsOpenSupplierAuth(state, action) {
      state.isOpenSupplierAuth = action.payload;
    },
    setIsGoogle(state, action) {
      state.isGoogle = action.payload;
    },
    setEvents(state, action) {
      state.allEvents = action.payload;
    },
    setSupplierForLabel(state, action) {
      state.SupplierForLabel = action.payload;
    },
    setCurrentEvent(state, action) {
      // console.log(action.payload)
      state.currentEvent = { ...action.payload.currentEvent };
    },
    setCurrentSupplierEvent(state, action) {
      // console.log(action.payload)
      state.currentSupplierEvent = { ...action.payload.currentSupplierEvent };
    },
    setCurrentEventForChecklist(state, action) {
      state.currentEventForChecklist = { ...action.payload.currentEventForChecklist };
    },
    setAddEvent(state, action) {
      state.currentEvent = { ...action.payload.currentEvent };
    },
    setCurrentCategory(state, action) {
      state.currentCategory = action.payload.currentCategory;
    },
    setCurrentSearch(state, action) {
      state.currentSearch = action.payload.currentSearch;
    },
    setIsNeedPlace(state, action) {
      state.isNeedPlace = action.payload;
    },
    setHavePlace(state, action) {
      state.isHavePlace = action.payload;
    },
    setTermsRole(state, action) {
      state.isTermsRole = action.payload;
    },
    setCurrentStep(state, action) {
      state.currentStep = action.payload;
    },
    setAllServices(state, action) {
      state.allServices = action.payload;
    },
    setCodeModalIsOpen(state, action) {
      state.codeModalIsOpen = action.payload;
    },
    setIsOpenMobleAuth(state,action){
      state.isOpenMobileAuth = action.payload
    }
  },
  extraReducers: {
    [fetchAllEvetns.pending]: (state, action) => {
      state.loadingStatus = 'loading';
      state.error = null;
    },
    [fetchAllEvetns.fulfilled]: (state, action) => {
      state.loadingStatus = 'resolved';
      state.allEvents = action.payload;
    },
    [fetchAllEvetns.rejected]: (state, action) => {
      state.loadingStatus = 'rejected';
      state.error = action.payload;
    },
    [fetchAllEvetnsHomePage.pending]: (state, action) => {
      state.loadingStatus = 'loading';
      state.error = null;
    },
    [fetchAllEvetnsHomePage.fulfilled]: (state, action) => {
      state.loadingStatus = 'resolved';
      state.allEvents = action.payload;
    },
    [fetchAllEvetnsHomePage.rejected]: (state, action) => {
      state.loadingStatus = 'rejected';
      state.error = action.payload;
    },
    [fetchAllRecommended.pending]: (state, action) => {
      state.loadingStatus = 'loading';
      state.error = null;
    },
    [fetchAllRecommended.fulfilled]: (state, action) => {
      state.loadingStatus = 'resolved';
      state.allRecommended = action.payload;
    },
    [fetchAllRecommended.rejected]: (state, action) => {
      state.loadingStatus = 'rejected';
      state.error = action.payload;
    },
    [fetchAllServices.fulfilled]: (state, action) => {
      state.allServices = action.payload;
    },
    [fetchAllServices.rejected]: (state, action) => {
      state.allServices = [];
    },
  },
});

export const {
  setIsGoogle,
  setIsOpenSupplierAuth,
  setIsOpenUserAuth,
  setIsOpenUserReg,
  setEvents,
  setSupplierForLabel,
  setCurrentEvent,
  setCurrentSupplierEvent,
  setAddEvent,
  setHavePlace,
  setTermsRole,
  setCurrentCategory,
  setCurrentSearch,
  setIsNeedPlace,
  setCurrentEventForChecklist,
  setCurrentStep,
  setAllServices,
  setCodeModalIsOpen,
  setIsOpenMobleAuth
} = mainSlice.actions;

export default mainSlice.reducer;
