import { useState, useEffect, useMemo } from "react";
import TableRow from "./tableRow/TableRow";
import styles from "./ServiceTable.module.scss";
import {useDispatch} from "react-redux";
import classNames from "classnames";
import { useLocation, useParams } from "react-router-dom";
import shekelSymbol from "../../../assets/images/icon-payment-ils.png";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import axios from "../../../api/axios";
import {TEMP_USER_KEY} from "../../consts/local_keys";
import {setCurrentEventChat} from "../../store/chatSlice";
import Dialog from "@mui/material/Dialog";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import { setCurrentEvent, setCurrentEventForChecklist, setCurrentSupplierEvent } from "../../store/mainSlice";
import errorCodesHe from '../errorMessenger/ErrorCodes.json' 
import { useSelector } from "react-redux";
// import AddEvent from './addEventPopup/AddEventPopup'
import { savePath } from "../../store/returnToUrlSlice";
import { setIsOpenUserAuth } from "../../store/mainSlice";
import { useAnalyticsEvents } from "../../hooks/useAnalyticsEvents";
import Select from "../../components/selectComponent/Select";
import { setSelectedEventTypeId } from "../../store/suppliersSlice";
import { setCurrentEventChecklist } from "../../store/checkListSlice";

function ServiceTable(props) {
  const dispatch = useDispatch();
  const {ChatWithProvider} = useAnalyticsEvents();
  const {auth} = useAuth()
  let { pathname } = useLocation();
  const [totalAmount, setTotalAmount] = useState(0);
  const [minTotalAmount, setMinTotalAmount] = useState(0);
  const [defaultEventType, setdefaultEventType] = useState([]);
  const [currentSupplierEvent, setcurrentSupplierEvent] = useState({});
  const [activeId, setActiveId] = useState(null);
  const [isShowWarnPopup, setIsShowWarnPopup] = useState({text:'',isShow:false});
  // const [isShowCreateEventPopup, setIsShowCreateEventPopup] = useState(false);
  // const [canOpenChat, setcanOpenChat] = useState(true);
  const [currId,setCurrId] = useState(0)
  // const [addOnChecklist, setaddOnChecklist] = useState(false);
//  const [eventsInCheckList,setEventsInCheckList] = useState();
  const axiosPrivate = useAxiosPrivate();
  const {isShowPrices, selectCategory, services, venueServices, isFavourite, eventTypeId, eventName, categoryId} = props;
  let ax = auth?.roles?.[0] === "USER" ? axiosPrivate : axios
  const chooseActive = (activeRowId) => {
    setActiveId(activeRowId);
  };
  const {selectedCategoryId, selectedEventTypeId} = useSelector(
    (state) => state.suppliers
  );
  // let { currentEvent:currentChecklist } = useSelector((state) => state.checklist);
  const { suppliersFilters,venuesFilters } = useSelector((state) => state.suppliers);
  const currentDateProps = useMemo(() => {
    const isVenuesFilters = categoryId === 1 && Object.keys(venuesFilters).length !== 0
    if(isVenuesFilters){
      return {
        date:venuesFilters?.dateFrom ? `&date=${venuesFilters.dateFrom}` : '',
        dateTo:venuesFilters?.dateTo ? `&dateTo=${venuesFilters?.dateTo}` : ""
      }
    }
    return {
      date:suppliersFilters?.eventDate ? `&date=${suppliersFilters.eventDate}` : '',
      dateTo: ""
    }
  },[suppliersFilters,venuesFilters,categoryId])


  let { currentEvent } = useSelector((state) => state.main);
  const routeParams = useParams();
  const navigate = useNavigate();
  let currentRole = (auth?.roles?.length) ? auth?.roles[0] : '';

  const [sendInfo, setsendInfo] = useState(false);
  const [inCheckList, setInCheckList] = useState(true);
  const [checkListExists, setcheckListExists] = useState(false);
  const [isContractCreated, setIsContractCreated] = useState(false);

  const getTotalAmount = (action, cost) => {
    let result = totalAmount;
    if (action) result += cost;
    if (!action) result -= cost;
    result = +result.toFixed(2);
    setTotalAmount(Math.round(result));
  };
  const getMinTotalAmount = (action, cost) => {
    let result = minTotalAmount;
    if (action) result += cost;
    if (!action) result -= cost;
    result = +result.toFixed(2);
    setMinTotalAmount(Math.round(result));
  };
  
  const openChat = async (isCheckListExists,inCheckList,checkListSuppliersLimit) => {
    if(currentRole !== ''){
      // setInCheckList(prev=>{
     if(isCheckListExists){
      if(inCheckList || checkListSuppliersLimit){
        const evTypeId = eventTypeId ? eventTypeId : selectedEventTypeId
        const evName = eventName ? eventName : currentEvent.name
        let eventType = {id: evTypeId, name: evName};
        dispatch(setCurrentEventChat({currentEvent: eventType}));
      //   console.log('тут2')
      //   navigate(`/personal/chat/${supplierId}/${userId}/${evTypeId}/${categoryId}/0`);
      // } else{
        // setaddOnChecklist(true);
        // canAddOnChecklist(true)
      }
      // } else{
        // setcanOpenChat(false);
        // setIsShowCreateEventPopup(true)
      } 
      canAddOnChecklist(true)
      // return prev
    //  }
    }
  };

  const canAddOnChecklist = async(value)=>{
    if(value){
      let supplierId = Number(routeParams.id);
      const evType = eventTypeId ?? selectedEventTypeId
        try {
          const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}checklist/${evType}/data?supId=${supplierId}&categoryId=${categoryId}${currentDateProps.date}${currentDateProps.dateTo}`);
          if(result){
            dispatch(setCurrentEventChecklist({currentEvent:result.data}))
            dispatch(setCurrentEventForChecklist({currentEventForChecklist: {id: evType, name:''}}));
            dispatch(setCurrentEventForChecklist({currentEventForChecklist: {id: String(result.data.checkListId), name:''}}));
            setsendInfo(true);
            const evTypeId = eventTypeId ? eventTypeId : selectedEventTypeId
            const evName = eventName ? eventName : currentEvent.name
            let supplierId = routeParams.id;
            let userId = JSON.parse(auth.user).id;
            let eventType = {id: evTypeId, name: evName};
            dispatch(setCurrentEventChat({currentEvent: eventType}));
            navigate(`/personal/chat/${supplierId}/${userId}/${evTypeId}/${categoryId}/${result.data.checkListId}`);
          } 
        } catch(err){
          setIsShowWarnPopup({text:errorCodesHe[Number(err.response.data.code)], isShow:true})
        }
    } else{
      const evTypeId = eventTypeId ? eventTypeId : selectedEventTypeId
      const evName = eventName ? eventName : currentEvent.name
      let supplierId = routeParams.id;
      let userId = JSON.parse(auth.user).id;
      let eventType = {id: evTypeId, name: evName};
      dispatch(setCurrentEventChat({currentEvent: eventType}));
      navigate(`/personal/chat/${supplierId}/${userId}/${evTypeId}/${categoryId}/0`);
    }
   }

  const openChatWrapper = async()=>{
   // await fetchChecklist()
    let supplierId = routeParams.id;
    const catID = selectedCategoryId.id && selectedCategoryId?.id !== 0 ? selectedCategoryId?.id : (currId && currId !== 0 ? currId : categoryId)
    const response = auth?.roles?.[0] === '' ? ax.get(`supplier/profile/${supplierId}/preview?categoryId=${catID || 0}&eventTypeId=${ selectedEventTypeId || 0}&userToken=${localStorage.getItem(TEMP_USER_KEY)}${currentDateProps.date}${currentDateProps.dateTo}`) :
       await ax.get(`supplier/profile/${supplierId}/preview?categoryId=${catID || 0}&eventTypeId=${ selectedEventTypeId || 0}${currentDateProps.date}${currentDateProps.dateTo}`);
    const result = response;
    setInCheckList(result.data.inCheckList)
    setIsContractCreated(result.data.contractCreated)
    setcheckListExists(result.data.checkListExists)
    await openChat(result.data.checkListExists,result.data.inCheckList,result.data.checkListSuppliersLimit)
  }
  function goToLogin() {
    if(isShowPrices){
    dispatch(savePath(pathname));
    dispatch(setIsOpenUserAuth(true))
    }
  }

  
  function login() {
    dispatch(savePath(pathname));
    dispatch(setIsOpenUserAuth(true))
  }

  // function closepopup(){
  //   setIsShowCreateEventPopup(false);
  //   setaddOnChecklist(true);
  // }

  const addChecklist = async () => {
    let supplierId = Number(routeParams.id);
    const evType = eventTypeId ?? selectedEventTypeId
    if(currentRole !== ''){
      try {
        const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}checklist/${evType}/data?supId=${supplierId}&categoryId=${categoryId}${currentDateProps.date}${currentDateProps.dateTo}`);
        if(result){
          dispatch(setCurrentEventChecklist({currentEvent:result.data}))
        dispatch(setCurrentEventForChecklist({currentEventForChecklist: {id: evType, name:''}}));
        dispatch(setCurrentEventForChecklist({currentEventForChecklist: {id: String(result.data.checkListId), name:''}}));
        setsendInfo(true);
        } 
      } catch(err){
        setIsShowWarnPopup({text:errorCodesHe[Number(err.response.data.code)], isShow:true})
      }
    } else {
      try {
        const result = await axios.post(`${process.env.REACT_APP_API_PATH}checklist/${evType}/data/anonymous?supId=${supplierId}&categoryId=${categoryId}&userToken=${localStorage.getItem(TEMP_USER_KEY) || ''}${currentDateProps.date}${currentDateProps.dateTo}`);
        if(result){
          dispatch(setCurrentEventChecklist({currentEvent:result.data}))
          dispatch(setCurrentEventForChecklist({currentEventForChecklist: {id: evType, name:''}}));
          dispatch(setCurrentEventForChecklist({currentEventForChecklist: {id: String(result.data.checkListId), name:''}}));
          setsendInfo(true);
        } 
      } catch(err){
        setIsShowWarnPopup({text:errorCodesHe[Number(err.response.data.code)], isShow:true})
      }
    }
  };
  function goToSuppliers() {
    navigate(`/suppliers`);
  }
    
  function goToChecklist() {
    if(currentRole !== ''){
      navigate(`/personal/checklist`);
    } else{
      navigate(`/personal-guest/checklist`);
    }
  }

  const onChangeOption = (option) => {
    
    setcurrentSupplierEvent(option);
    dispatch(setSelectedEventTypeId(option.id))
    dispatch(setCurrentSupplierEvent({ currentSupplierEvent: option }));
    dispatch(setCurrentEvent({ currentEvent: option })); 
    navigate(`/`);
    setTimeout(() => {
      const encodedName = encodeURIComponent(option.name);
      navigate(`/supplier/${routeParams.id}/${encodedName}`,{ replace: true });
    }, 10);
  };
  
  useEffect(() => {
    const fetchData = async () => { 
     if((selectedCategoryId.id && selectedCategoryId?.id !== 0) || (selectCategory?.id)){
      try {
      const result = await axios.get(`${process.env.REACT_APP_API_PATH}supplier/profile/${routeParams.id}/category/${selectedCategoryId?.id || selectCategory?.id}/eventTypes`);
      if(result){
        setdefaultEventType(result.data);
      } 
    } catch(err){
      setIsShowWarnPopup({text:errorCodesHe[Number(err.response.data.code)], isShow:true})
    }
  }
  };
  fetchData();
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectCategory]);
  const fetchChecklist = async ()=>{
    let supplierId = routeParams.id;
    const catID = selectedCategoryId.id && selectedCategoryId?.id !== 0 ? selectedCategoryId?.id : (currId && currId !== 0 ? currId : categoryId)
    const response = (auth?.roles?.[0] === '' || auth?.roles?.[0] === undefined) ? ax.get(`supplier/profile/${supplierId}/preview?categoryId=${catID || 0}&eventTypeId=${ selectedEventTypeId || 0}&userToken=${localStorage.getItem(TEMP_USER_KEY)}${currentDateProps.date}${currentDateProps.dateTo}`)
        :await ax.get(`supplier/profile/${supplierId}/preview?categoryId=${catID || 0}&eventTypeId=${ selectedEventTypeId || 0}${currentDateProps.date}${currentDateProps.dateTo}`);
    
    const result = await response;
    //await ax.get(`supplier/profile/${supplierId}/preview?categoryId=${catID || 0}&eventTypeId=${ selectedEventTypeId || 0}&userToken=${localStorage.getItem(TEMP_USER_KEY)}`)
    setInCheckList(result.data.inCheckList)
    setIsContractCreated(result.data.contractCreated)
    setcheckListExists(result.data.checkListExists)
  }
  useEffect(()=>{
    if(((selectedCategoryId?.id && selectedCategoryId?.id !== 0) || (selectCategory?.id && selectCategory?.id !== 0))){
      setCurrId(selectedCategoryId?.id)
      fetchChecklist()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedCategoryId, selectCategory])
  // useEffect(() => {
  //   const fetch = async()=>{
  //     const result = await ax.get(`${process.env.REACT_APP_API_PATH}checklist`)
  //     setEventsInCheckList(result.data);
  //   }
  //   fetch()
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[]);
  return (
    <>
      {isShowPrices && categoryId !==1  ? 
        <>
          <div className={styles.titleContainer}>
            <h3>תחום השירות ומחירים</h3>
            &nbsp; &nbsp;
            <p className={styles.titleContainerHint}>(אפשרות לבחירה מרובה)</p>
          </div>

          <table className={styles.serviceTable}>
            <tbody>
              {currentRole !== 'USER' && services &&
                services.map((service) => (
                  <TableRow
                    supplierId={routeParams.id}
                    eventTypeId={eventTypeId}
                    categoryId={categoryId}
                    service={service}
                    getTotalAmount={getTotalAmount}
                    getMinTotalAmount={getMinTotalAmount}
                    key={service.id}
                    id={service.id}
                    activeId={activeId}
                    chooseActive={chooseActive}
                  />
                ))}
                {currentRole === 'USER' && 
                    <TableRow
                      isContractCreated={isContractCreated}
                      supplierId={routeParams.id}
                      eventTypeId={eventTypeId}
                      categoryId={categoryId}
                      service={''}
                      getTotalAmount={getTotalAmount}
                      getMinTotalAmount={getMinTotalAmount}
                      key={1}
                      id={1}
                      activeId={activeId}
                      chooseActive={chooseActive}
                    />
                }
            </tbody>
            <tfoot></tfoot>
          </table>
          {currentRole !== 'SUPPLIER' && currentRole !== 'LABEL' &&   <hr className={styles.devider} align="right" /> }
          {currentRole !== 'SUPPLIER' && currentRole !== 'LABEL' && <table
            className={classNames(styles.serviceTable, styles.tableForTotalValue)}
          >
            <tbody>
              <tr>
                <td className={styles.totalValueCell}>סך הכל</td>
                <td className={styles.totalValueCell}>
                  {/* <span className={styles.shekelSymbol}>₪</span> */}
                  {categoryId ===2 ? `${Math.round(minTotalAmount)}-${Math.round(totalAmount)}` : `${Math.round(totalAmount)}`}
                  <img
                    src={shekelSymbol}
                    alt="shekel"
                    className={styles.shekelSymbol}
                  />
                </td>
                <td className={styles.totalValueCell}></td>
              </tr>
            </tbody>
            <tfoot></tfoot>
          </table> }
        </>
        :
        !!(categoryId === 1 && isShowPrices && venueServices) ? 
        <div className={styles.venueContainer}>
          <div className={styles.venueRows}>
            <div className={styles.venueRow}>
              {venueServices?.priceRangeTo!==null && venueServices?.priceRangeFrom!==null && <>
                <span>{"טווח מחירים"}</span>
                <span>{` ${venueServices?.priceRangeTo} - ${venueServices?.priceRangeFrom} `}</span>
              </>}
            </div>    
            <div className={styles.venueRow}>
            {venueServices?.amountOfGuestsTo!==null && venueServices?.amountOfGuestsFrom!==null && <>  
                <span>{"כמות אורחים "}</span>
                <span>{` ${venueServices?.amountOfGuestsTo} - ${venueServices?.amountOfGuestsFrom} `}</span>
              </>}
            </div>    
            <div className={styles.venueRow}>
            {venueServices?.weekendEventName!==null && <>
                <span>{"אירועים בסופש"}</span>
                <span>{` ${venueServices?.weekendEventName} `}</span></>}
              </div>    
            <div className={styles.venueRow}>
            {venueServices?.parkingOptionName!==null && <>
                <span>{"אפשרויות חניה"}</span>
                <span>{` ${venueServices?.parkingOptionName} `}</span>
              </>}
            </div>    
            <div className={styles.venueRow}>
            { venueServices?.regionsName?.length ?<><span>{"איזור"}</span>
              
              <div className={styles.rowRegions}>
                {venueServices?.regionsName.map((region, index) => {
                  return (
                    <div key={region.id} className={styles.supplierLocationsContent}>
                      {region}
                      {index !== venueServices?.regionsName.length - 1 && " , "}
                    </div>
                    );
                  })}
              </div></> : <span>{""}</span>
              }
            </div>    
            <div className={styles.venueRow}>
            {venueServices?.kosherName!==null && <>
                <span>{"כשרות \\ סוג תפריט"}</span>
                <span>{` ${venueServices?.kosherName} `}</span>
              </>}
            </div>    
            <div className={styles.venueRow}>
              {venueServices?.venueTypeName!==null && <>
                <span>{"סוג מתחם"}</span>
                <span>{` ${venueServices?.venueTypeName} `}</span>
              </>} 
            </div>    
          </div>
        </div>
        :
        <div className={styles.noPrices}>
          <div className={styles.titleContainer}>
            <h3>תחום השירות ומחירים</h3>
            &nbsp; &nbsp;
            <p className={styles.titleContainerHint}>{"(לא זמין)"}</p>
          </div>
          <div className={styles.description}>
                {"על מנת לראות את מחירון השירותים של הספק עליך תחילה לבחור סוג אירוע מבוקש. כך נוכל להציג לך את המחירים הרלוונטיים עבור האירוע שלך."}
                {/* <span className={styles.link} onClick={() => navigate("/suppliers")}>
                  {"הספקים שלנו"}
                </span> */}
          </div>
          <div className={styles.firstSelections}>
            <div className={styles.select}>
              <Select
                fontFamilyHeader={"Heebo-Light"}
                borderRadius={"0.3125rem"}
                backgroundColor={"#EEE9E5"}
                options={defaultEventType}
                onChangeOption={onChangeOption}
                selectedOption={currentSupplierEvent}
              />
            </div>
          </div>
        </div>
      }

      <div className={styles.buttonsContainer}>
       { currentRole !== 'SUPPLIER' && currentRole !== 'LABEL' && <button 
          style={{cursor: (isShowPrices) ? 'pointer' : 'default', backgroundColor: (isShowPrices) ? "" : "#A1A1A1", color: (isShowPrices) ? "" : "rgba(255, 255, 255, .31)"}}
          onClick={(e)=>{
            const obj = {
              userName: (auth?.user ? JSON.parse(auth?.user)?.name : ""),
              supplierName: props?.supplierName,
              userPhone: (auth?.user ? JSON.parse(auth?.user)?.phoneNumber : ''),
              // url: pathname,
            }
            ChatWithProvider(obj);
            (isShowPrices && currentRole ? openChatWrapper() : goToLogin())}} 

          className={classNames(styles.contactButton)}
          >קבלו הצעת מחיר</button> }

        { currentRole !== 'SUPPLIER' && currentRole !== 'LABEL' && <button style={{opacity: isShowPrices ? '':"0.27", cursor: isShowPrices  ? 'pointer' : 'default'}}
          className={classNames(styles.addToCheckListButton, "like_prov")}onClick={(e)=>(isShowPrices ? addChecklist() : '')} >
        {/* { currentRole !== 'SUPPLIER' && currentRole !== 'LABEL' && <button style={{opacity: (isShowPrices && !inCheckList) ? '':"0.27", cursor: (isShowPrices && !inCheckList) ? 'pointer' : 'default'}}
          className={classNames(styles.addToCheckListButton, "like_prov")}onClick={(e)=>(isShowPrices && !inCheckList ? addChecklist() : '')} > */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="51"
            height="51"
            viewBox="0 0 51 51"
          >
            <g id="list-plus" transform="translate(0 -0.333)">
              <rect
                id="Rectangle_219"
                data-name="Rectangle 219"
                width="51"
                height="51"
                transform="translate(0 0.333)"
                fill="none"
              />
              <path
                id="Line_15"
                data-name="Line 15"
                d="M29.112-4.773h-35.5A1.614,1.614,0,0,1-8-6.386,1.614,1.614,0,0,1-6.386-8h35.5a1.614,1.614,0,0,1,1.614,1.614A1.614,1.614,0,0,1,29.112-4.773Z"
                transform="translate(14.454 18.994)"
              />
              <path
                id="Line_16"
                data-name="Line 16"
                d="M29.112-4.773h-35.5A1.614,1.614,0,0,1-8-6.386,1.614,1.614,0,0,1-6.386-8h35.5a1.614,1.614,0,0,1,1.614,1.614A1.614,1.614,0,0,1,29.112-4.773Z"
                transform="translate(14.454 31.903)"
              />
              <path
                id="Line_17"
                data-name="Line 17"
                d="M14.59-4.773H-6.386A1.614,1.614,0,0,1-8-6.386,1.614,1.614,0,0,1-6.386-8H14.59A1.614,1.614,0,0,1,16.2-6.386,1.614,1.614,0,0,1,14.59-4.773Z"
                transform="translate(14.454 44.811)"
              />
              <path
                id="Line_18"
                data-name="Line 18"
                d="M3.295-4.773H-6.386A1.614,1.614,0,0,1-8-6.386,1.614,1.614,0,0,1-6.386-8H3.295A1.614,1.614,0,0,1,4.908-6.386,1.614,1.614,0,0,1,3.295-4.773Z"
                transform="translate(43.498 44.811)"
              />
              <path
                id="Line_19"
                data-name="Line 19"
                d="M-6.386,4.908A1.614,1.614,0,0,1-8,3.295V-6.386A1.614,1.614,0,0,1-6.386-8,1.614,1.614,0,0,1-4.773-6.386V3.295A1.614,1.614,0,0,1-6.386,4.908Z"
                transform="translate(48.339 39.971)"
              />
            </g>
          </svg>
          <span className={styles.checkListButtonDescription}>
            {"עדכון / חזרה לצק ליסט"}
            {/* {"הוספה לצ'קליסט"} */}
          </span>
        </button> }
        {/* {isRegUser && currentRole !== 'SUPPLIER' && currentRole !== 'LABEL' && ( */}
        { currentRole !== 'SUPPLIER' && currentRole !== 'LABEL' && (
        <> 
        { currentRole === 'USER' && <button style={{opacity: (currentRole !== '') ? '':"0.27", cursor: (currentRole !== '') ? 'pointer' : 'default'}}
            className={classNames(styles.addToFavoriteButton, "like_prov")}
            onClick={props.addRemoveFavourite}
          > 
            <svg
              id="LIKE"
              xmlns="http://www.w3.org/2000/svg"
              width="33.161"
              height="30.61"
              viewBox="0 0 33.161 30.61"
            >
              <path
                id="Icon_ionic-md-heart-empty"
                data-name="Icon ionic-md-heart-empty"
                d="M27.417,4.5A9.808,9.808,0,0,0,19.955,8a9.808,9.808,0,0,0-7.461-3.5,9.015,9.015,0,0,0-9.119,9.175c0,6.337,5.636,11.423,14.173,19.267l2.407,2.168,2.407-2.168C30.9,25.1,36.536,20.012,36.536,13.675A9.015,9.015,0,0,0,27.417,4.5ZM20.976,30.75l-.335.311-.686.622-.686-.622-.335-.311a101.321,101.321,0,0,1-9.781-9.725c-2.232-2.766-3.22-5.03-3.22-7.35A6.63,6.63,0,0,1,7.823,8.948a6.487,6.487,0,0,1,4.671-1.9A7.311,7.311,0,0,1,18,9.642l1.953,2.32,1.953-2.32a7.284,7.284,0,0,1,5.508-2.591,6.519,6.519,0,0,1,4.679,1.9,6.644,6.644,0,0,1,1.889,4.727c0,2.312-1,4.584-3.22,7.35A101.525,101.525,0,0,1,20.976,30.75Z"
                transform="translate(-3.375 -4.5)"
                fill={isFavourite ? "#f5b9ab" : "#242323"}
              />
            </svg>
          </button> }
          { currentRole === '' &&
            <button style={{ cursor: 'pointer' }}
            className={classNames(styles.addToFavoriteButton, "like_prov")} onClick={(e)=>{login()}} 
            // onClick={props.addRemoveFavourite}o
          >
          <svg
            id="LIKE"
            xmlns="http://www.w3.org/2000/svg"
            width="33.161"
            height="30.61"
            viewBox="0 0 33.161 30.61"
          >
            <path
              id="Icon_ionic-md-heart-empty"
              data-name="Icon ionic-md-heart-empty"
              d="M27.417,4.5A9.808,9.808,0,0,0,19.955,8a9.808,9.808,0,0,0-7.461-3.5,9.015,9.015,0,0,0-9.119,9.175c0,6.337,5.636,11.423,14.173,19.267l2.407,2.168,2.407-2.168C30.9,25.1,36.536,20.012,36.536,13.675A9.015,9.015,0,0,0,27.417,4.5ZM20.976,30.75l-.335.311-.686.622-.686-.622-.335-.311a101.321,101.321,0,0,1-9.781-9.725c-2.232-2.766-3.22-5.03-3.22-7.35A6.63,6.63,0,0,1,7.823,8.948a6.487,6.487,0,0,1,4.671-1.9A7.311,7.311,0,0,1,18,9.642l1.953,2.32,1.953-2.32a7.284,7.284,0,0,1,5.508-2.591,6.519,6.519,0,0,1,4.679,1.9,6.644,6.644,0,0,1,1.889,4.727c0,2.312-1,4.584-3.22,7.35A101.525,101.525,0,0,1,20.976,30.75Z"
              transform="translate(-3.375 -4.5)"
              fill={isFavourite ? "#f5b9ab" : "#242323"}
            />
          </svg>
        </button>
          }
          </>
        )}
      </div>
      <section>
        <Dialog
            open={isShowWarnPopup.isShow}
            onClose={setIsShowWarnPopup}
            >
            <DialogContent>
              <DialogContentText>{isShowWarnPopup.text}</DialogContentText>
            </DialogContent>
              <DialogActions sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
              <Button onClick={()=>setIsShowWarnPopup({...isShowWarnPopup,isShow:false})}>
                המשך       
              </Button>
            </DialogActions>
          </Dialog>
      </section>
        {/* <Dialog
          open={isShowCreateEventPopup}
          onClose={setIsShowCreateEventPopup}
          maxWidth={'md'}
          fullWidth={true}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                background: '#EEE9E5 0% 0% no-repeat padding-box',
                maxHeight: '43.75rem'
              },
            },
          }}
        >
        <div className={styles.closePopup}>
          <svg style={{cursor:'pointer'}}
          xmlns="http://www.w3.org/2000/svg"
          width="25.477"
          height="25.47"
          viewBox="0 0 25.477 25.47"
          onClick={() => setIsShowCreateEventPopup(false)}
          >
          <path
            id="Icon_ionic-ios-close"
            data-name="Icon ionic-ios-close"
            d="M27.042,24.024l9.1-9.1a2.132,2.132,0,1,0-3.015-3.015l-9.1,9.1-9.1-9.1a2.132,2.132,0,1,0-3.015,3.015l9.1,9.1-9.1,9.1a2.132,2.132,0,0,0,3.015,3.015l9.1-9.1,9.1,9.1a2.132,2.132,0,0,0,3.015-3.015Z"
            transform="translate(-11.285 -11.289)"
            fill="#242323"
          />
          </svg>
        </div>
          <DialogContent>
            <AddEvent  closePopup={closepopup} redirect={canOpenChat} paramForChat={{id: eventTypeId, name: eventName}} categoryId={categoryId} />
          </DialogContent>
        </Dialog> */}
    <Dialog hideBackdrop={true} sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "400px",
          boxShadow: 'none',
          overflow: 'hidden',
          borderRadius: '0'
        },
      },
    }} open={sendInfo} fullWidth={true} className={styles.infoDialog}>
      <div className={styles.mainPopup}>
        <div className={styles.close}>
          <svg style={{cursor:'pointer'}}
          xmlns="http://www.w3.org/2000/svg"
          width="25.477"
          height="25.47"
          viewBox="0 0 25.477 25.47"
          onClick={() => {fetchChecklist();setsendInfo(!sendInfo);}}
          >
          <path
            id="Icon_ionic-ios-close"
            data-name="Icon ionic-ios-close"
            d="M27.042,24.024l9.1-9.1a2.132,2.132,0,1,0-3.015-3.015l-9.1,9.1-9.1-9.1a2.132,2.132,0,1,0-3.015,3.015l9.1,9.1-9.1,9.1a2.132,2.132,0,0,0,3.015,3.015l9.1-9.1,9.1,9.1a2.132,2.132,0,0,0,3.015-3.015Z"
            transform="translate(-11.285 -11.289)"
            fill="#242323"
          />
          </svg>
        </div>
        <div className={styles.blockCalendar}>
          <div className={styles.infoDialogClose}>
            <div className={styles.infoDialogtitle}>
            הספק התווסף לצ'קליסט בהצלחה
          <div className={styles.dialogButtons}>
            <button onClick={() => goToChecklist()} className={styles.buttonVideo}>המשך לצ'קליסט</button>
            <button onClick={() => goToSuppliers()} className={styles.buttonVideo}>חזור לספקים</button>
          </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
    {/* <Dialog hideBackdrop={true} sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "400px",
          boxShadow: 'none',
          overflow: 'hidden',
          borderRadius: '0'
        },
      },
    }} open={addOnChecklist} fullWidth={true} className={styles.infoDialog}>
      <div className={styles.mainPopup}>
        <div className={styles.blockCalendar}>
          <div className={styles.infoDialogClose}>
            <div className={styles.infoDialogtitle}>?האם להוסיף את הספק לצ'קליסט
            <div className={styles.buttonsBlock}>
              <button className={styles.buttonDialog} onClick={()=>canAddOnChecklist(true)}>כן</button>
              <button className={styles.buttonDialog} onClick={()=>canAddOnChecklist(false)}>לא</button>
            </div>
            </div>
          </div>
        </div>
      </div>

    </Dialog> */}
    </>
  );
}

export default ServiceTable;
