import { Button, Typography } from '@mui/material';
import React from 'react';
import styles from '../../../styleBasicForm.module.scss';
import { useFormContext } from 'react-hook-form';
import { EVENT_TYPE_ENUM, STEPS_ENUM } from '../stepForm.config';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentStep } from '../../../../../../store/mainSlice';
const TypeEvent = () => {
  const dispatch = useDispatch();
  const { watch, setValue } = useFormContext();
  const { currentStep } = useSelector((state) => state.main);
  const typeEvent = watch('typeEvent');
  const onChangeType = (type) => {
    if (currentStep === STEPS_ENUM.TYPE_EVENT)
      dispatch(setCurrentStep(STEPS_ENUM.WHAT_CELEBRATING));
    if (type === typeEvent) return;
    setValue('typeEvent', type);
  };
  return (
    <div className={styles.one_step}>
      <Typography className={styles.one_step_label}>סוג האירוע</Typography>
      <div className={styles.field_container} style={{ display: 'flex' }}>
        <Button
          onClick={() => onChangeType(EVENT_TYPE_ENUM.PRIVATE)}
          variant={typeEvent === EVENT_TYPE_ENUM.PRIVATE ? 'contained' : 'outlined'}
        >
          פרטי
        </Button>
        <Button
          onClick={() => onChangeType(EVENT_TYPE_ENUM.BUSINESS)}
          sx={{ marginRight: { xs: '7px', md: '10px' } }}
          variant={typeEvent === EVENT_TYPE_ENUM.BUSINESS ? 'contained' : 'outlined'}
        >
          עסקי
        </Button>
      </div>
    </div>
  );
};

export default TypeEvent;
