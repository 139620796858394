import styles from "./Chat.module.scss";
import * as React from 'react';
import {Tooltip, FormControlLabel} from '@mui/material';
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import useAuth from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import send from "./send.svg"
import shekel from "./shekel.svg"
import file_upload from "./file_upload.svg"
import file_on_chat from "./file_on_chat.svg"
import meet_mobile from "./meet_mobile.svg"
import meet_desctop from "./meet_desctop.svg"
import arrowMobile from "./arrowUp.svg"
import shekelSymbol from "../../../assets/images/icon-payment-ils.png";
//import Select from "../../components/selectComponent/Select";
import arrow from "../../../assets/images/for-pages/caret-down.png";
import arrowUp from "../../../assets/images/for-pages/caret-up.png";
import {
  setCurrentEventChat //, setCurrentCategory
} from "../../store/chatSlice";
import Dialog from "@mui/material/Dialog";
import Typography from '@mui/material/Typography'
import classNames from "classnames";
import { useNavigate } from "react-router";
import {
  setSelectedCategoryId,
  setSelectedEventTypeId,
} from "../../store/suppliersSlice";
import {setCurrentEvent} from "../../store/mainSlice";
import dayjs from "dayjs";
import he from "dayjs/locale/he"; // load on demand
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import Calendar from "./calendar/CustomCalendar";
import { setErr } from "../../store/forAll/errSlice";
import axios from "axios";
import Popup from "./popup/Popup";
import Payment from "./payment/Payment";
//import axios2 from "../../../api/axios";
import MetaFavicon from '../../components/metaComponents/metaFavicon'
import { getDatabase, ref, set, onValue, limitToLast, update, endAt, orderByKey, push, query, limitToFirst} from "firebase/database";
import Checkbox from '@mui/material/Checkbox';
import {Box} from "@mui/material";

import PopupTest from "../personalSupplierArea/documents/popup/Popup";
import { useAnalyticsEvents } from "../../hooks/useAnalyticsEvents";
import { DAY_STATUS } from "../../consts/dayStatus";

function Chat() {
  dayjs.locale(he);
  dayjs.extend(localizedFormat);
  dayjs.extend(customParseFormat);
  const {Video, Purchase, NewContractSignUp} = useAnalyticsEvents();
  const dispatch = useDispatch();
  const [services, setServices] = useState({data:[], loading:false});
  const [contractServices, setcontractServices] = useState({data:[], loading:true});
  const [isOpenDropDown, setIsOpenDropDown] = useState(true);
  const [paymentServices, setpaymentServices] = useState({data:[], loading:true});
  const [contractId, setcontractId] = useState(0);
  const [chatUniqueId, setchatUniqueId] = useState(0);
  const [allSum, setallSum] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [openMeeting, setOpenMeeting] = React.useState(false);
  const [supplierContractButton, setsupplierContractButton] = useState(false);
  const [currSum, setCurrSum] = useState(0)
  const navigate = useNavigate();
  const [globalDataSet, setglobalDataSet] = useState({data:[],loading:true});
  const [isOpen, setIsOpen] = useState(false);
  const [showPayment, setshowPayment] = useState(false);
  const [editPermission, seteditPermission] = useState(false);
//  const [phoneNumber, setphoneNumber] = useState('');
//  const [nameforZapier, setnameforZapier] = useState('');
  const checkPaymentFunc = useRef(false);
  const toggling = () => setIsOpen(!isOpen);
  const [isOpenSecond, setIsOpenSecond] = useState(false);
  const togglingSecond = () => setIsOpenSecond(!isOpenSecond);
  const [lastPopup, setLastPopup] = useState(false)
  const [, setTooltip] = useState(false)
  const [chatt, setChatt] = useState({data:null,loading:true});
  const [allMsg, setallMsg] = useState({data:null,loading:true});
  const [allMsgCount, setallMsgCount] = useState(0);
  const [lastKey, setlastKey] = useState('');
  const [allMsgKey, setallMsgKey] = useState([]);
  const [flag, setflag] = useState(0);
  const [countShow, setcountShow] = useState(0);
  const { currentEvent } = useSelector((state) => state.chat);
  const { currentEvent:currentChecklist } = useSelector((state) => state.checklist);
  const [haveDescription, setHaveDescription] = useState(false)
  const [haveCheckbox, setHaveCheckbox] = useState(true)
  const [isOpenQuatation, setIsOpenQuatation] = useState(false)
  const [textInTextarea, setTextInTextarea] = useState('')
  // const { currentCategory } = useSelector((state) => state.chat);
  const msgDisplayRef = useRef();
  //let defaultEventType = {id:0, name:''};
  const [isRequestContract, setIsRequestContract] = useState(false)
  const [allEvents, setEvents] = useState({data:[], loading: true});
  const [clickContracts, setclickContracts] = useState(false);
  const [googleInviteInfo, setgoogleInviteInfo] = useState(false);
  const [statusUnPaid, setstatusUnPaid] = useState(false);
  const [, setpaymentUserButton] = useState(false);
  const [allInformation, setAllInformation] = useState({})
  const [allInformationAnal, setAllInformationAnal] = useState({})
  const [googleInvite, setgoogleInvite] = useState(false);
  const [secondId, setsecondId] = useState(false);
  const [secondAddress, setAddress] = useState(false);
  const [name, setName] = useState({});
  const [supName, setsupName] = useState({});
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  let currentRole = auth.roles[0];
  const routeParams = useParams();
  const [eventTypeId, seteventTypeId] = useState(routeParams.eventTypeId);
  let supplierId = routeParams.supplierId;
  let userId = routeParams.userId;
  let userName = JSON.parse(auth?.user) ? JSON.parse(auth?.user).name : '';
  let categoryId = routeParams.categoryId;
  let checkListId = +routeParams.checkListId
  const [openContract, setopenContract] = useState(false);
  const [openPayment, setopenPayment] = useState(false);
  const [isPaySuccess, setIsPaySuccess] = useState(false);
  let [message, setMessage] = useState('');  
  let [file, setfile] = useState('');  
  let [, setvalueIds] = useState({categoryId:'',userId:'',supplierId:'',eventTypeId:'',name:'', contractId:''});  
  const [isCallApi, setIsCallApi] = useState(false);
  // const [paginateConfig, setPaginateConfig] = useState({
  //   currentPage: 0,
  //   itemsPerPage: 10,
  //   totalItems: 0,
  //   totalPages: 0
  // });  
  const [contractStatus, setContractStatus] = useState({isSent:false, isHave: false, isPaid:false, isSigned: false, reqContract: false})
  const [textInButton, setTextInButton] = useState('')
  const [textInSuppButton, setTextInSuppButton] = useState('')
  
  const [firstDialog, setfirstDialog] = useState(true);
  const [secondDialog, setsecondDialog] = useState(false);
  const [isPartialDayBlocked, setIsPartialDayBlocked] = useState(false);
  const [eventDate, seteventDate] = useState(null);

  const [, setthirdDialog] = useState(false);
  const [dataForUser,setDataForUser] = useState({amountOfGuests:0,eventDate: null,venue:null, prePayment: null})
  const [contractSign, setContractSign] = useState(false)
  const resetErrConfig = {
    isEmpty: false,
    isShort: false,
    isLong: false,
    isNumber: true,
    isValid: true
  }
  const [errorState1, setErrorState1] = useState(resetErrConfig)
  const [errorState2, setErrorState2] = useState(resetErrConfig)
  const [inputState1 , setInputState1] = useState('')
  const [inputState2 , setInputState2] = useState('')

  /*const firstStep = () => {
    setfirstDialog(false);
    setsecondDialog(true);
  }*/
  
  function is_israeli_id_number(id) {
    id = String(id).trim();
    if (id.length > 9 || isNaN(id)) return false;
    id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
      return Array.from(id, Number).reduce((counter, digit, i) => {
        const step = digit * ((i % 2) + 1);
        return counter + (step > 9 ? step - 9 : step);
      }) % 10 === 0;
  }

  const sendAnalitics = () => {
    const obj = ({
            userName: (auth?.user ? JSON.parse(auth?.user)?.name : ""),
            supplierName: supName,
            userPhone: (auth?.user ? JSON.parse(auth?.user)?.phoneNumber : ''),
            // url: pathname,
          })
    Video(obj)
  }
  const sendAnalitics2 = async(id) => {

    let obj = ({
      userName: (auth?.user ? JSON.parse(auth?.user)?.name : ""),
      supplierName: supName,
      userPhone: (auth?.user ? JSON.parse(auth?.user)?.phoneNumber : ''),
      total: allInformationAnal?.totalAmount,
      discount: (currSum||discount),
    })

    try {
      const res = await axiosPrivate.get(`/contract/details?contractId=${id}`);
      if (res?.error) {
        Purchase(obj)
      } else {
        const data = await res.data;
        obj.total = data?.totalAmount;
        obj.discount = data?.discountPrice;
        Purchase(obj)
      }
        
    } catch (error) {
      Purchase(obj)
    }

    
  }
  const sendAnalitics3 = async(id) => {
    let obj = ({
      userName: (auth?.user ? JSON.parse(auth?.user)?.name : ""),
      supplierName: supName,
      userPhone: (auth?.user ? JSON.parse(auth?.user)?.phoneNumber : ''),
      total: allInformationAnal?.totalAmount,
      discount: (currSum||discount),
    })

    try {
      const res = await axiosPrivate.get(`/contract/details?contractId=${id}`);
      if (res?.error) {
        
        NewContractSignUp(obj)
      } else {
        const data = await res.data;
        obj.total = data?.totalAmount;
        obj.discount = data?.discountPrice;
        NewContractSignUp(obj)
      }
        
    } catch (error) {
      NewContractSignUp(obj)
    }
    
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  
  useEffect(()=>{
    const chatMess = chatt
    if(chatMess.data && !isRequestContract){
      setIsRequestContract(chatMess.data.find(el=>el.message === 'שלח חוזה') !== undefined)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[chatt])
  useEffect(()=>{
    const chatMess = chatt
    if(chatMess.data){
      const mess = chatMess.data.find(el=>el.message === 'החוזה נשלח') !== undefined
    if(!mess && contractStatus.isSent){
        sendCustomMessage(5)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[openContract, openPopup, contractStatus])
  const secondStep = async (data) => {
    const res = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}contract?eventTypeId=${eventTypeId}&categoryId=${categoryId}&supId=${supplierId}&checkListId=${currentChecklist?.checkListId || 0}`);
    setcontractId(res.data);
    if(data.userIdNumber.length === 9 || (secondId && data.additionalUserIdNumber.length === 9)){
      setErrorState1(resetErrConfig)
      setErrorState2(resetErrConfig)
      if(!is_israeli_id_number(data.userIdNumber) || (secondId && !is_israeli_id_number(data.additionalUserIdNumber))){
        if(!is_israeli_id_number(data.userIdNumber)){
          setErrorState1(prev =>{
            return{...prev , isValid:false }
          })
        }
        if(secondId && !is_israeli_id_number(data.additionalUserIdNumber)){
          setErrorState2(prev =>{
            return{...prev , isValid:false }
          })
        } 
      } else{
        let contactForm = {
          supplierId: supplierId,
          eventTypeId: eventTypeId,
          categoryId: categoryId,
          userIdNumber: data.userIdNumber,
          city: data.city,
          address: data.address,
          additionalUserIdNumber: secondId ? data.additionalUserIdNumber : '',
          additionalCity: secondAddress ? data.additionalCity : '',
          additionalAddress: secondAddress ? data.additionalAddress : '',
          checkListId:checkListId ? checkListId : currentChecklist?.checkListId || 0,
        }       
        setOpen(false);
        setContractSign(true)
        const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}contract/request?contractId=${contractId !== 0 ? contractId : res.data}`, contactForm);
        if(result){
          if(currentRole === 'USER'){   
            const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}contract/user/check?eventTypeId=${eventTypeId}&categoryId=${categoryId}&supplierId=${supplierId}&checkListId=${checkListId ? +checkListId : currentChecklist?.checkListId || 0}`);
            setclickContracts(result.data.updateAllowed);
            sendCustomMessage(2);
          } else if(currentRole !== 'USER'){
            const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}contract/supplier/check?eventTypeId=${eventTypeId}&categoryId=${categoryId}&userId=${userId}&supId=${supplierId}&checkListId=${checkListId ? +checkListId :currentChecklist?.checkListId || 0}`);
            setclickContracts(result.data.updateAllowed);
          }
          //getNewMessage();
          setsecondDialog(false);
          reset()
          setErrorState1(resetErrConfig)
          setErrorState2(resetErrConfig)
          setInputState1('')
          setInputState2('')
          setTimeout(() => {
            setthirdDialog(true);
          }, 1000);
        }
      }
    }
  }

  const defaultValues = {
    userId: userId,
    supplierId: supplierId,
    eventTypeId: eventTypeId,
    categoryId: categoryId,
    message: "",
  };

  const checkContractFromUser = async() => {
    const result = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}contract/chat/details?eventTypeId=${eventTypeId}&categoryId=${categoryId}&userId=${userId}&supId=${supplierId}&checkListId=${checkListId ? +checkListId :currentChecklist?.checkListId || 0}`);
    if(result){
      setcontractId(result.data.id);
      checkSupplierContractButton(result.data.id);
      setcontractServices({data:result.data,loading:false});
    }
  };

  const checkContract = async() => {
    const result = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}contract?eventTypeId=${eventTypeId}&categoryId=${categoryId}&supId=${supplierId}&checkListId=${checkListId ? +checkListId :currentChecklist?.checkListId || 0}`);
    if(result){
      setcontractId(result.data);
      checkPaymentUserButton(result.data);
    }
  };
  const sendContract = async (idContract, isPartialDayBlocked) =>{
    let data = {
      supplierId: Number(supplierId),
      eventTypeId: Number(eventTypeId),
      categoryId: Number(categoryId),
      additionalNotes: '',
      checkListId:checkListId ? +checkListId :currentChecklist?.checkListId || 0,
    }
    let id = 0
    setcontractId(prev=>{
      id = prev
      return prev 
    })

    const res = await axiosPrivate.post(`/contract/approve?userId=${userId}&contractId=${id === 0 ? idContract : id}`,data);
    if (res?.error) {
      dispatch(
        setErr({
          message: res?.payload?.response?.data?.message,
          code: res?.payload?.response?.data?.code,
        })
      );
    } else{
      seteventDate(res.data.eventDate);
        if(res.data.eventDate){
          if(isPartialDayBlocked){setIsPartialDayBlocked(true)}//var for second dialog to hide button disable all day
          setsecondDialog(true);
        }
    }
  } 

  const checkContractForPopup = async(id) => {
    const result = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}contract/chat/details?eventTypeId=${eventTypeId}&categoryId=${categoryId}&userId=${userId}&supId=${supplierId}&checkListId=${checkListId ? +checkListId :currentChecklist?.checkListId || 0}`);
    if(result){
      setcontractId(result.data.id);
      if(result.data.dateStatus === DAY_STATUS.AVAILABLE){
        sendContract(id)
      } else if( result.data.dateStatus === DAY_STATUS.PARTIALLY ){
        sendContract(id, true)
      } else{
        //error that day blocked
        dispatch(
          setErr({
            message: "לא ניתן לשלוח חוזה מכיוון שיום זה מוגדר כתפוס",
          })
        );
      }
    }
  };

  const checkSupplierContractButton = async(id) => {
    const result = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}contract/approve/check?contractId=${id}`);
    if(result){
      setsupplierContractButton(result.data);
    }
  };
  
  const checkSupplierButton = async() => {
    const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}contract/supplier/check?eventTypeId=${eventTypeId}&categoryId=${categoryId}&userId=${userId}&supId=${supplierId}&checkListId=${checkListId ? +checkListId :currentChecklist?.checkListId || 0}`);
    setclickContracts(result.data.updateAllowed);
  };

  const handleClickOpenSecond = async(checkbox=true) => {
    setHaveCheckbox(checkbox)
    //temporally clickContracts
    if(clickContracts){
      setOpenPopup(true);
    }
  };

  const contractClosePopup = async () => {
    setOpenPopup(false);
    setCurrSum(0);
  };

  /*const handleClickOpen = () => {
    if(clickContracts && (contractStatus.isSent)){
      setOpen(true);
    } else{
      sendCustomMessage(4)
    }
  };*/

  const handleClickMeeting = () => {
    if(googleInvite){
      setOpenMeeting(true);  
    } else{
      setgoogleInviteInfo(true);
    }
  };

  const checkPaymentUserButton = async (id) => {  
    const result = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}contract/check/user/pay?contractId=${id}`); 
    if(result){
     setpaymentUserButton(result.data);
     checkPaymentFunc.current = true;
    } else{
      dispatch(
        setErr({
          message: result?.payload?.response?.data?.message,
          code: result?.payload?.response?.data?.code,
        }) )
    }
  };


  /*const sendPaymentRequeat = async () => {  
    const result = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}contract/check/sup/pay?userId=${userId}&categoryId=${categoryId}&eventTypeId=${eventTypeId}&supplierId=${supplierId}`); 
    if(result){
      getNewMessage();
    } else{
      dispatch(
        setErr({
          message: result?.payload?.response?.data?.message,
          code: result?.payload?.response?.data?.code,
        }) )
    }
  };*/

  const handleClose = () => {
    setOpen(false);
    reset();
    setErrorState1(resetErrConfig)
    setErrorState2(resetErrConfig)
    setInputState1('')
    setInputState2('')
    setTimeout(() => {
      setthirdDialog(false);
      setsecondDialog(false);
      setfirstDialog(true);
    }, 200);
  };

  const handleClosePopup = async (data) => {
    let contactForm = {
      eventTypeId: eventTypeId,
      categoryId: categoryId,
      userId: userId,
    // contractId: contractServices.data.id,
      discount: Number(currSum||discount),
      checkListId:checkListId ? +checkListId : currentChecklist?.checkListId || 0
    }
    //const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}contract/approve/discount?supId=${supplierId}`, contactForm);
    try{
      const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}contract/approve/discount?supId=${supplierId}`, contactForm);
     if(!result?.error){
      //for updating all prices after changing discount
      changeServices(eventTypeId)
      
      setCurrSum(0);
      if(isOpenQuatation){
        if(result){
          if(!haveCheckbox){
            let message = result.data.message;
            sendCustomMessageWithMessage(1,message);
            setOpenPopup(false);
          }
        }
        setIsOpenQuatation(false)
      } else{
        if(result){
          setcontractId(result.data.contractDto.id)
          checkContractForPopup(result.data.contractDto.id)
          if(result){
            if(currentRole === 'USER'){   
              const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}contract/user/check?eventTypeId=${eventTypeId}&categoryId=${categoryId}&supplierId=${supplierId}&checkListId=${checkListId ? +checkListId :currentChecklist?.checkListId || 0}`);
              setclickContracts(result.data.updateAllowed);
            } else if(currentRole !== 'USER'){
              const result1 = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}contract/supplier/check?eventTypeId=${eventTypeId}&categoryId=${categoryId}&userId=${userId}&supId=${supplierId}&checkListId=${checkListId ? +checkListId : currentChecklist?.checkListId || 0}`);
              setclickContracts(result1.data.updateAllowed);
              checkPermission();
              if((result.data.contractDto.dateStatus === DAY_STATUS.AVAILABLE) || (result.data.contractDto.dateStatus === DAY_STATUS.PARTIALLY)){
                sendCustomMessage(5);
              }
            }
            checkSupplierContractButton(contractId);
            //getNewMessage();
            setOpenPopup(false);
          }
        }
      }
    }
    } catch (error){
      dispatch(
        setErr({
          message: error?.response?.data?.message,
          code: 1927,
      }) )
    }
    // setCurrSum(0);
    // if(isOpenQuatation){
    //   if(result){
    //     if(!haveCheckbox){
    //       let message = result.data.message;
    //       sendCustomMessageWithMessage(1,message);
    //       setOpenPopup(false);
    //     }
    //   }
    //   setIsOpenQuatation(false)
    // } else{
    //   if(result){
    //     console.log('result.data.contractDto.id', result.data.contractDto.id);
    //     setcontractId(result.data.contractDto.id)
    //     checkContractForPopup(result.data.contractDto.id)
    //     if(result){
    //       if(currentRole === 'USER'){   
    //         const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}contract/user/check?eventTypeId=${eventTypeId}&categoryId=${categoryId}&supplierId=${supplierId}`);
    //         setclickContracts(result.data.updateAllowed);
    //       } else if(currentRole !== 'USER'){
    //         const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}contract/supplier/check?eventTypeId=${eventTypeId}&categoryId=${categoryId}&userId=${userId}&supId=${supplierId}`);
    //         setclickContracts(result.data.updateAllowed);
    //         checkPermission();
    //         sendCustomMessage(5);
    //       }
    //       checkSupplierContractButton(contractId);
    //       //getNewMessage();
    //       setOpenPopup(false);
    //     }
    //   }
    // }
  };

  const sendCustomMessageWithMessage =  async (type, message) => {

    let data = defaultValues;
    setcustomTypeMessage(true);
    if(type === 1){
    data.message = message;
    data.fromUserType = 'SYSTEM_SUPPLIER';
    data.toUserType = 'USER';
    data.sender = currentRole;
    data.receiver = 'USER';
    data.isRead = false;
    } 
    data.timestamp = Date.now();
    data.fileUrl = '';
    if(currentRole !== 'LABEL'){  
      data.name = userName
    } else{
      data.name = supName
    }
    const db = getDatabase();
  try{
    const postListRef = ref(db, '/chats/' + chatUniqueId + '/messages');
    const newPostRef = push(postListRef);
    set(newPostRef, data);
    setMessage('');
  } catch (error){
    dispatch(
      setErr({
        message: error,
        code: 400,
    }) )
  }
  }

  const uploadFile = async (value, name = "") => {
    setfile(value.file);
    let data = defaultValues;
    setcustomTypeMessage(false);
    data.message = value.name;
    const formData = new FormData();
    formData.append("file", value.file);
    const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}chats/chat/file?eventTypeId=${eventTypeId}&categoryId=${categoryId}&userId=${userId}&supId=${supplierId}&checkListId=${checkListId ? +checkListId :currentChecklist?.checkListId || 0}`,
    formData, {
      headers: { "Content-Type": "multipart/form-data"} });
    if(result){
      data.fromUserType = 'FILE_UPLOAD';
      data.toUserType = 'SUPPLIER';
      data.sender = currentRole;
      data.receiver = 'SUPPLIER';
      data.isRead = false;
      data.timestamp = Date.now();
      data.fileUrl = result.data;
      if(currentRole !== 'LABEL'){  
        data.name = userName
      } else{
        data.name = supName
      }
      const db = getDatabase();
    try{
      const postListRef = ref(db, '/chats/' + chatUniqueId + '/messages');
      const newPostRef = push(postListRef);
      set(newPostRef, data);
      setMessage('');
      setfile('');
      if(chatt.data.length === 0 && currentRole === 'USER'){
        sendZapierUserFirstMessage();
      }
    } catch (error){
      dispatch(
        setErr({
          message: error,
          code: 400,
      }) )
    }
   }
  };

  const handleCloseMeeting = () => {
    setOpenMeeting(false);
  };
  
  useEffect(() => {
    let div = document.getElementsByClassName('samyBody');
    div[0].scrollTop = 0
    const fetchData = async () => {
    const result = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}user/choose/service?userId=${userId}&supId=${supplierId}&eventTypeId=${eventTypeId}&categoryId=${categoryId}&checkListId=${checkListId ? +checkListId :currentChecklist?.checkListId || 0}`);
    setServices({data: result.data.userServiceTypeChooseDtos, loading: false});

    setDataForUser(prev=>{
      return{...prev, prePayment: result.data.prePayment}
    })
    setallSum(result.data.discountPrice);
    setDiscount(result.data.discount);
    // console.log('result', result.data);
    if(currentRole !== 'USER'){
      checkPermission();
      checkContractFromUser();
    } else{
      checkContract();
    }
    };
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

// useEffect(() => {
//   const fetchData = async () => {
//   const result = currentRole === 'USER' ? await axios.get(`${process.env.REACT_APP_API_PATH}main/user/phone?id=${userId}`) :
//     await axios.get(`${process.env.REACT_APP_API_PATH}main/sup/phone?id=${supplierId}`);
//     setphoneNumber('972' + result.data.phoneNumber.split('0')[1]);
//     setnameforZapier(result.data.name);
//   };
//   fetchData();
// // eslint-disable-next-line react-hooks/exhaustive-deps
// }, []);

const [globalData, setglobalData] = useState({});
const [firstLoading, setfirstLoading] = useState(true);
//const [updateEvent, setupdateEvent] = useState(true);
 useEffect(() => { 
  let data = globalData;
  setallMsg({data: [], loading: true});
  setIsCallApi(true);
 // if(updateEvent){
  if(data){
    setallMsgCount(Object.values(data).length);
    let oldState = allMsgKey;
    setallMsgKey(Object.keys(data).concat(oldState))
   // setlastKey((Object.keys(data))[0]);
   if(data.length > 0 && firstLoading){
    let key = data;
    setlastKey(key[0].key);
    setfirstLoading(false);
   }
    if(flag === 0){
      loadFirstMessages(0, Object.keys(data).map(y => {
        return {
          key: y,
          ...data[y]
        }
      }).reverse());
      setflag(1);
    } else {
    // let actualState = chatt.data;
     let testData = globalDataSet.data;
   //  console.log(actualState.reverse()[0].timestamp === Object.values(data).reverse()[0].timestamp, chatt.data[0].timestamp, Object.values(data).reverse()[0].timestamp);
     if(!(testData.reverse()[0]?.timestamp === Object.values(data).reverse()[0]?.timestamp)){


      setglobalDataSet({data: (chatt.data).concat([Object.keys(data).map(y => {
        return {
          key: y,
          ...data[y]
        }
      }).reverse()[0]]), loading: false})
      setChatt({data: (chatt.data).concat([Object.keys(data).map(y => {
        return {
          key: y,
          ...data[y]
        }
      }).reverse()[0]]), loading: false});
      //console.log((chatt.data).concat([Object.values(data).reverse()[0]]));
     // console.log([Object.values(data).reverse()[0]].concat(actualState),[Object.values(data).reverse()[0]].concat(actualState));
     }
    //  setChatt({data: actualState.concat([Object.values(data).reverse()[0]]), loading: false});
      if(!showPayment){
        let div = document.getElementsByClassName(msgDisplayRef.current.className);
        setTimeout(() => {
          div[0].scrollTop = div[0].scrollHeight;
          setIsCallApi(false);
        }, 500);
      } else{
        setIsCallApi(false);
      }
    }
  } else {
    setChatt({data: [], loading: false});
    setallMsg({data: [], loading: false});
    setIsCallApi(false);
  }
 // }
 // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [globalData]);

useEffect(() => {
  const fetchData = async () => {
  const result = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}chats/chat?userId=${userId}&supId=${supplierId}&categoryId=${categoryId}&eventTypeId=${eventTypeId}&checkListId=${checkListId ? +checkListId : currentChecklist?.checkListId || 0}&chatUrl=${window.location.href}`);
  const secondResult = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}chats/chat/event/details?userId=${userId}&eventTypeId=${eventTypeId}&supId=${supplierId}&checkListId=${checkListId ? +checkListId :currentChecklist?.checkListId || 0}`);
  
    setDataForUser(prev=>{
      return {
        ...prev,
        ...secondResult.data
    }
  })
  setchatUniqueId(result.data?.chatId);
  if(result){
    const {data} = result
    writeUserData(data?.chatId);
    firstLoad.current = false;
    if(data?.new){
      setMessage(data?.message)
      setTimeout(() => {
        sendMessage(data?.message,data?.chatId)
      },100)
    }
  }
  };
  fetchData();
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

// useEffect(() => {
//   console.log(chatt.data);
// // eslint-disable-next-line react-hooks/exhaustive-deps
// }, [chatt.data]);

//const [updateMessage, setupdateMessage] = useState(null);
const [customTypeMessage, setcustomTypeMessage] = useState(false);

const changekIsReadStatus = async (chatUniqueId, data) => {
  // console.log(1);
  const db = getDatabase();
  let newData = [...data];
const messageKey = newData?.reverse()[0]?.key;
const lstMsg = {isRead:true}
let newChat = [...chatt?.data];
let newChatLabel = [...chatt?.data];
if(messageKey && ((currentRole !== 'LABEL' && newChat?.reverse()?.[0]?.sender !== currentRole) || (currentRole === 'LABEL' && newChatLabel?.reverse()?.[0]?.sender === 'USER')) && chatUniqueId){
  // console.log(true);
  update(query(ref(db,  'chats/' + chatUniqueId +'/messages/' + messageKey)), lstMsg);
}
}

const writeUserData = async (chatUniqueId) => {
  try {
    let c = 10
    const db = getDatabase();
    const starCountRef = query(ref(db,  'chats/' + chatUniqueId +'/messages'),limitToLast(10) ); 
    const nextCountRef = query(ref(db,  'chats/' + chatUniqueId +'/messages'),limitToFirst(1) ); 
    const request = lastKey !== '' ? nextCountRef : starCountRef;
    //setlastKey(null);
    onValue(request, (snapshot) => {
    let data = snapshot.val();
    if(!customTypeMessage && !!data){
     setglobalData(Object.keys(data).map(y => {
      return {
        key: y,
        ...data[y]
      }
    }));
    }
    // snapshot.forEach(function(child) {
    // });
    setallMsg({data: [], loading: true});
    setIsCallApi(true);
    if(data){
     setallMsg({data: Object.keys(data).map(y => {
      return {
        key: y,
        ...data[y]
      }
    }).reverse(), loading: false});
      if(c === 10){
      //  let lastMsg = Object.values(data).reverse();
        // if(lastMsg[0].sender !== currentRole && !lastMsg[0].isRead){
        //   lastMsg[0].isRead = true; 
        //   update(query(ref(db,  'chats/' + chatUniqueId +'/messages/'+ Object.keys(data).reverse()[0])), lastMsg[0]);
        // //  console.log(lastMsg, lastMsg[0].isRead);
        // }
        c++;
        loadFirstMessages(0, Object.keys(data).map(y => {
          return {
            key: y,
            ...data[y]
          }
        }).reverse());
        let newState = flag
        setflag(newState++);
        setIsCallApi(false);
      } else {
        setIsCallApi(false);
        //checkUpdateReadMessage(data);

        // setupdateMessage(Object.keys(data).map(y => {
        //   return {
        //     key: y,
        //     ...data[y]
        //   }
        // }));
      }
    } else {
      setChatt({data: [], loading: false});
      setallMsg({data: [], loading: false});
      setIsCallApi(false);
    }
});

} catch(error){
  dispatch(
    setErr({
      message: error,
      code: 400,
  }) )
}
}
useEffect(()=>{
  if(chatt?.data && chatUniqueId){
    changekIsReadStatus(chatUniqueId, chatt.data)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[chatt, chatUniqueId])

const loadFirstMessages = async (count, value) =>{
  setIsCallApi(true); 
  const actualState = chatt.data; 
  if(count){
    setChatt({data:[],loading:true});
    setglobalDataSet({data:[],loading:true});
    if(actualState){
      setChatt({data: allMsg.data.slice(countShow, 10).reverse().concat(actualState), loading: false});
      setglobalDataSet({data: allMsg.data.slice(countShow, 10).reverse().concat(actualState), loading: false});
    } else {
      setChatt({data: allMsg.data.slice(countShow, 10).reverse(), loading: false});      
      setglobalDataSet({data: allMsg.data.slice(countShow, 10).reverse(), loading: false});    
    }
  } else {
    setChatt({data:[],loading:true});
    if(actualState){
      setChatt({data: value.slice(countShow, 10).reverse().concat(actualState), loading: false});
      setglobalDataSet({data: value.slice(countShow, 10).reverse().concat(actualState), loading: false});
    } else {
      setChatt({data: value.slice(countShow, 10).reverse(), loading: false});
      setglobalDataSet({data: value.slice(countShow, 10).reverse(), loading: false});
    }
  }
  let actualData = countShow;
  setcountShow(actualData + 10);
  if(!showPayment){
    let div = document.getElementsByClassName(msgDisplayRef.current.className);
    setTimeout(() => {
      div[0].scrollTop = div[0].scrollHeight;
      setIsCallApi(false);
    }, 500);
  } else{
    setIsCallApi(false);
  }
}

const sendMessage =  async (messageProp,chatUniqueIdProp) => {
  let data = defaultValues;
  const currentChatUniqueId = chatUniqueId ? chatUniqueId : chatUniqueIdProp ? chatUniqueIdProp : null
  if(!currentChatUniqueId) return
  setcustomTypeMessage(false);
  data.message = message ? message : messageProp ? messageProp :'';
if(file === '' && data.message !== ''){
if(currentRole === 'USER'){
  data.fromUserType = currentRole;
  data.toUserType = 'SUPPLIER';
  data.sender = currentRole;
  data.receiver = 'SUPPLIER';
  data.isRead = false;
} else {
  data.fromUserType = 'SUPPLIER';
  data.toUserType = 'USER';
  data.sender = 'SUPPLIER';
  data.receiver = 'USER';
  data.isRead = false;
}
  data.timestamp = Date.now();
  data.fileUrl = '';
  if(currentRole !== 'LABEL'){  
    data.name = userName
  } else{
    data.name = supName
  }
  const db = getDatabase();
try{
  const postListRef = ref(db, '/chats/' + currentChatUniqueId + '/messages');
  const newPostRef = push(postListRef);
  set(newPostRef, data);
  setMessage('');
  if(chatt?.data?.length === 0 && currentRole === 'USER'){
    sendZapierUserFirstMessage();
  }
} catch (error){
  console.error(error)
  dispatch(
    setErr({
      message: error,
      code: 400,
  }) )
}
  } else if(file !== ''){
    const formData = new FormData();
    formData.append("file", file);
    const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}chats/chat/file?eventTypeId=${eventTypeId}&categoryId=${categoryId}&userId=${userId}&supId=${supplierId}&checkListId=${checkListId ? +checkListId :currentChecklist?.checkListId || 0}`,
    formData, {
      headers: { "Content-Type": "multipart/form-data"} });
    if(result){
    if(currentRole === 'USER'){
      data.fromUserType = currentRole;
      data.toUserType = 'SUPPLIER';
      data.sender = currentRole;
      data.receiver = 'SUPPLIER';
      data.isRead = false;
    } else {
      data.fromUserType = currentRole;
      data.toUserType = 'USER';
      data.sender = currentRole;
      data.receiver = 'USER';
      data.isRead = false;
    }
      data.timestamp = Date.now();
      data.fileUrl = result.data;
      if(currentRole !== 'LABEL'){  
        data.name = userName
      } else{
        data.name = supName
      }
      const db = getDatabase();
    
    try{
      const postListRef = ref(db, '/chats/' + currentChatUniqueId + '/messages');
      const newPostRef = push(postListRef);
      set(newPostRef, data);
      setMessage('');
      file('');
      if(chatt.data.length === 0 && currentRole === 'USER'){
        sendZapierUserFirstMessage();
      }
    } catch (error){
      dispatch(
        setErr({
          message: error,
          code: 400,
      }) )
    }
   }
  }
}

const sendCustomMessage =  async (type, isSendAnalitics) => {
  if(isSendAnalitics){
    sendAnalitics()
  }
  let data = defaultValues;
  setcustomTypeMessage(true);
  if(type === 1){
  data.message = "אני רוצה לערוך איתך שיחת וידאו, אנא שלח לי קישור לשיחה";
  data.fromUserType = 'SYSTEM_INVITE_REQUEST_USER';
  data.toUserType = 'SUPPLIER';
  data.sender = currentRole;
  data.receiver = 'SUPPLIER';
  data.isRead = false;
  } else if(type === 2){
  data.message = "CONTRACT_REQUEST_MESSAGE";
  data.fromUserType = 'SYSTEM_USER';
  data.toUserType = 'SUPPLIER';
  data.sender = currentRole;
  data.receiver = 'SUPPLIER';
  data.isRead = false;
  } else if(type === 3){
    data.message = 'דרישת תשלום';
    data.fromUserType = 'SYSTEM_INVITE_REQUEST_USER';
    data.toUserType = 'USER';
    data.sender = currentRole;
    data.receiver = 'USER';
    data.isRead = false;
    } else if(type === 4){
      data.message = 'שלח חוזה';
      data.fromUserType = 'SYSTEM_INVITE_REQUEST_USER';
      data.toUserType = 'USER';
      data.sender = currentRole;
      data.receiver = 'USER';
      data.isRead = false;
      } else if(type === 5){
        data.message = 'החוזה נשלח';
        data.fromUserType = 'SYSTEM_INVITE_REQUEST_USER';
        data.toUserType = 'USER';
        data.sender = currentRole;
        data.receiver = 'USER';
        data.isRead = false;
      }
  data.timestamp = Date.now();
  data.fileUrl = '';
  if(currentRole !== 'LABEL'){  
    data.name = userName
  } else{
    data.name = supName
  }
  const db = getDatabase();
try{
  const postListRef = ref(db, '/chats/' + chatUniqueId + '/messages');
  const newPostRef = push(postListRef);
  set(newPostRef, data);
  setMessage('');
  if(type === 4 || type === 1){
    if(chatt.data.length === 0 && currentRole === 'USER'){
      sendZapierUserFirstMessage();
    }
  }
  if(type === 4){
    if(currentRole === 'USER'){
      sendZapierUserWantContract();
    }
  }
  if(type ===1){
    if(currentRole === 'USER'){
      sendZapierUserWantsCall();
    }
  }
} catch (error){
  dispatch(
    setErr({
      message: error,
      code: 400,
  }) )
}
}

const checkPermission = async () => {  
  let id = currentRole === 'LABEL' ? supplierId : null;
  let request;
  if(currentRole === 'LABEL'){
    request = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}supplier/check/optional/label?id=${id}`);
  } else {
    request = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}supplier/check/optional/label`);
  }
  const result = request;
  if(result){
    seteditPermission( currentRole === 'LABEL' ? result.data.isOptionChat : result.data.isOptionChat);
  }
};

useEffect(() => {
 // const fetchData = async () => {
  //const result = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}user/choose/service?userId=${userId}&supId=${supplierId}&eventTypeId=${eventTypeId}&categoryId=${categoryId}`);
  setpaymentServices({data: [
    {id:1, name:'צילום סטילס', price:'456'},
    {id:2, name:'צילום סטילס', price:'1223'},
    {id:3, name:'test', price:'4000'},
    {id:4, name:'צילום סטילס', price:'533'},
    {id:5, name:'צילום סטילס', price:'10'},
  ], loading: false});
  setshowPayment(false);
 // };
 // fetchData();
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  const changeServices = async (id) =>{
  setServices({loading: true});
  const result = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}user/choose/service?userId=${userId}&supId=${supplierId}&eventTypeId=${id}&categoryId=${categoryId}&checkListId=${checkListId ? +checkListId :currentChecklist?.checkListId || 0}`);
  setDataForUser(prev=>{
    return{...prev, prePayment: result.data.prePayment}
  })
  setServices({data: result.data.userServiceTypeChooseDtos, loading: false});
  setallSum(result.data.discountPrice);
  setDiscount(result.data.discount);
  };
  
  const firstLoad = useRef(true);
  useEffect(() => {
    const fetchData = async () => {
      if(chatt.data !== null){
      const result = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}eventType/specify?userId=${userId}&supId=${supplierId}&categoryId=${categoryId}`);
      setEvents({data:result.data,loading:false});
      if(result.data !== null){
        result.data.map((item)=> {
          if(item.id === Number(eventTypeId)){
            dispatch(setCurrentEventChat({ currentEvent: item }));
          }
          return item
        })
      }
    }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(`${process.env.REACT_APP_API_PATH}main/chat/names?userId=${userId}&supId=${supplierId}`);
      setName(result.data);
      setsupName(result.data.supName);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //    axiosPrivate.post(`${process.env.REACT_APP_API_PATH}chats/read?userId=${userId}&supId=${supplierId}&categoryId=${categoryId}&eventTypeId=${eventTypeId}`);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  //  const checkEventType = async () => {
  //   const result = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}eventType/specify?userId=${userId}&supId=${supplierId}&categoryId=${categoryId}`);
  //   setEvents({data:result.data,loading:false});
  //   if(result.data !== null){
  //     result.data.map((item)=> {
  //       if(item.id === Number(eventTypeId)){
  //         dispatch(setCurrentEventChat({ currentEvent: item }));
  //       } 
  //       return item
  //     })
  //   }
  //  };

  useEffect(() => {
    const fetchData = async () => {
      if(currentRole === 'USER'){   
        const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}contract/user/check?eventTypeId=${eventTypeId}&categoryId=${categoryId}&supplierId=${supplierId}&checkListId=${checkListId ? +checkListId :currentChecklist?.checkListId || 0}`);
        setclickContracts(result.data.updateAllowed);
      } else if(currentRole !== 'USER'){
        checkGoogleInvite();
        const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}contract/supplier/check?eventTypeId=${eventTypeId}&categoryId=${categoryId}&userId=${userId}&supId=${supplierId}&checkListId=${checkListId ? +checkListId :currentChecklist?.checkListId || 0}`);
        setclickContracts(result.data.updateAllowed);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkGoogleInvite = async () =>{
    const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}calendar/google/auth/check`);
    setgoogleInvite(result.data);
  }
  const getServicesInfoForPayment = async () =>{
   // dispatch(setLoader(true));
    if(contractId === 0){
      try {
        let data = {
          supplierId: supplierId,
          eventTypeId: eventTypeId,
          categoryId: categoryId,
          checkListId: checkListId ? +checkListId : currentChecklist?.checkListId || 0,
        }
        const createdContract = await axiosPrivate.post(`/contract/request/prepayment`, data);
        if(createdContract){
        const res = await axiosPrivate.get(`/contract/details?contractId=${createdContract.data.id}`);
          if (res?.error) {
            dispatch(
              setErr({
                message: res?.payload?.response?.data?.message,
                code: res?.payload?.response?.data?.code,
              })
            );
          } else {
            const data = await res.data;
            setcontractId(data.id);
            let pdesc = `מקדמה ל ${data?.eventTypeName} עבור ${data?.supplierName}`
            setAllInformation({...data, pdesc})
            if(data?.totalAmount){
              setAllInformationAnal(data)
            }
            setshowPayment(true);
            checkPaymentFunc.current = true;
            checkPayment(createdContract.data.id);
          }
        }
        } catch (error) {
          // console.log(error)
        }
    } else{
      try {
        const res = await axiosPrivate.get(`/contract/details?contractId=${contractId}`);
          if (res?.error) {
            dispatch(
              setErr({
                message: res?.payload?.response?.data?.message,
                code: res?.payload?.response?.data?.code,
              })
            );
          } else {
            const data = await res.data;
            let pdesc = `מקדמה ל${data?.supplierName} עבור ${data?.eventTypeName}`
            setAllInformation({...data, pdesc})
            if(data?.totalAmount){
              setAllInformationAnal(data)
            }
            setshowPayment(true);
            checkPaymentFunc.current = true;
            checkPayment(0);
          }
          
        } catch (error) {
          // console.log(error)
        }
    }
  }

  const handleCloseTooltip = () => {
    setTooltip(false);
  };

  const handleOpenTooltip = () => {
    if (clickContracts) {
      setTooltip(true);
    }
  };

  const fetch = async () => {
    const res = await axiosPrivate.get(`/contract/status?eventTypeId=${eventTypeId}&categoryId=${categoryId}&userId=${userId}&supplierId=${supplierId}&checkListId=${checkListId ? +checkListId : currentChecklist?.checkListId || 0}`)
    setContractStatus(prev=>{
      return{
        isSent: res.data.contractStatus === "CREATED" || res.data.contractStatus === "SIGNED",
        isHave:res.data.hasContract,
        isPaid: false,
        reqContract: res.data.reqContract,
        isRequested:  res.data.contractStatus === "REQUEST",
        isSigned: res.data.contractStatus === "SIGNED",
        isContractStatusNull: res.data.contractStatus === null
      }
    })
  }
  useEffect(()=>{
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  useEffect(()=>{
    let timer = setInterval(() => {
    setContractStatus(prev=>{
      if((prev.isSent || !prev.reqContract) && currentRole === 'USER'){
        clearInterval(timer)
      } 
      else {
        fetch()
      }
      return prev;
    })
    }, 5000)
    return () => clearInterval(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  useEffect(()=>{
    const timer = setTimeout(() => {
      fetch()
    }, 200);
    return () => clearTimeout(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isOpen, openPopup, open, openContract,contractSign])

  const getActionForButton = ()=>{

    if(contractStatus.reqContract){
      if(contractStatus.isHave) {
        if(contractStatus.isSent) {
          if(contractStatus.isSigned) {
            getServicesInfoForPayment()
          } else {
            setOpen(true)
          }
        } else {
          //andleClickOpen()
          sendCustomMessage(4)
        }
      } else {
        sendCustomMessage(4)
        //console.log(0);
        //getServicesInfoForPayment()
      }
  } else{
    getServicesInfoForPayment()
  }
  }
  
  const getTextForButton = ()=>{
    if(contractStatus.reqContract){
      if(contractStatus.isHave) {
        if(contractStatus.isSent) {
          if(contractStatus.isSigned) {
            setTextInButton('מעבר לתשלום')
          } else {
            setTextInButton('חתום על חוזה')
          }
        } else {
          setTextInButton('שלח לי חוזה')
        }
      } else {
        setTextInButton('שלח לי חוזה')
      }
    } else{
    setTextInButton('מעבר לתשלום')
    }
  }


  const getTextForTextarea = ()=>{
    if(contractStatus.reqContract){
      if(contractStatus.isHave) {
        if(contractStatus.isSent) {
          if(contractStatus.isSigned) {
            setTextInTextarea('החוזה הושלם! כדי לסגור את העסקה סופית נותר רק להסדיר תשלום מקדמה באתר בצורה פשוטה ובטוחה. לביצוע התשלום לחצו על ״בצע תשלום״')
          } else {
            setTextInTextarea('נט לחתום על חוזה שהועבר אליכם על מנת שנוכל להמשיך')
          }
        } else {
          if(contractStatus.isRequested && isRequestContract){
            setTextInTextarea('המתן לשליחת החוזה של הספק')
          }else {
            setTextInTextarea('שימו לב: כדי לסגור עסקה עם הספק, יש לחתום על החוזה  (אל תדאגו, הכל דיגיטלי ומאוד פשוט וידודותי). לאחר מכן נוכל להתקדם לתשלום. כדי להתחיל לחצו על ״שלח לי חוזה״')
          }
        }
      } else {
        if(isRequestContract){
          setTextInTextarea('המתן לשליחת החוזה של הספק')
        } else {
        setTextInTextarea('שימו לב: כדי לסגור עסקה עם הספק, יש לחתום על החוזה  (אל תדאגו, הכל דיגיטלי ומאוד פשוט וידודותי). לאחר מכן נוכל להתקדם לתשלום. כדי להתחיל לחצו על ״שלח לי חוזה״')
        }
      }
    } else{
      setTextInTextarea('שימו לב: כדי לסגור עסקה עם הספק אין צורך לחתום חוזה. כל מה שנותר זה ללחוץ על כפתור התשלום ולהסדיר תשלום על המקדמה')
    }
  }

  const getActionForButtonSupplier = ()=>{
    if(contractStatus.reqContract){
    if(contractStatus.isHave) {
      if(contractStatus.isSent) {
        if(contractStatus.isSigned) {
          sendCustomMessage(3);
        } else {
          sendCustomMessage(3);
        //  console.log('דרישת תשלום');
          // navigate("/personal/documents");
        }
      } else {
       // console.log('החוזה נשלח');
        handleClickOpenSecond()
      }
    } else {
    //  console.log('החוזה נשלח');
      handleClickOpenSecond()
    }
  } else{
    sendCustomMessage(3);
 //   console.log('דרישת תשלום');
  }
  }
  const getTextForButtonSupplier = ()=>{
    if(contractStatus.reqContract){
    if(contractStatus.isHave) {
      if(contractStatus.isSent) {
        if(contractStatus.isSigned) {
          setTextInSuppButton('דרישת תשלום')
          } else {
            setTextInSuppButton('דרישת תשלום')
          }
        } else {
          setTextInSuppButton('שלח חוזה')
        }
      } else {
        setTextInSuppButton('דרישת חוזה')
      }
    } else{
      setTextInSuppButton('דרישת תשלום')
    }
  }

  useEffect(()=>{
    getTextForButton()
    getTextForButtonSupplier()
    getTextForTextarea()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[contractStatus, isRequestContract])


  const [canCallScrollNewData, setIcanCallScrollNewData] = useState(true);
  function scrollEvent(e) { 
     
    const scrollLocation = e.target.scrollTop; // how far user scrolled
    if (scrollLocation === 0 && !isCallApi && canCallScrollNewData) {
    setIsCallApi(true); 
    try {
      const db = getDatabase();
      const nextCountRef = query(ref(db,  'chats/' + chatUniqueId +'/messages'),orderByKey(),endAt(lastKey),limitToLast(11) ); 
      setIsCallApi(true);
      onValue(nextCountRef, (snapshot) => {
      let val = []
      snapshot.forEach(function(child) {
        if(child.key !== lastKey){
          val.push(child.val())
        }
      });
      let data = snapshot.val();
      data = Object.keys(data).map(y => {
        return {
          key: y,
          ...data[y]
        }
      })
      setallMsg({data: [], loading: true});
      if(data){
        let setIsNewItems = true;
        let oldKey = lastKey;
        if(Object.keys(data)[0] === oldKey){
          setIsNewItems = false;
        }
        let oldMsg = allMsgCount;
        setallMsgCount(Number(Object.values(data).length) + Number(oldMsg));
  
        let oldState = allMsgKey;
        setallMsgKey(Object.keys(data).concat(oldState))
        //setlastKey((Object.keys(data))[0]);
        if(data.length > 0){ 
          let newKey = true;
          let allMessages = chatt.data
          let key = data;
          allMessages.map((item)=>{
            if(item.key === key[0].key){
              newKey = false;
            }
            return item
          })
          if(newKey){
            setlastKey(key[0].key);
          } else {
            setIcanCallScrollNewData(false);
          }
          // let key = data;
          // setlastKey(key[0].key);
        }
        let oldMessages = allMsg;
        setallMsg({data:  Object.values(data).reverse().concat(oldMessages), loading: false});
        let actualState = chatt.data;
        if(setIsNewItems){
          //setChatt({data: val.reverse().concat(actualState), loading: false});
          setChatt({data: val.concat(actualState), loading: false});
        }
          let newState = flag
          setflag(newState++);
        if(!showPayment){
          let div = document.getElementsByClassName(msgDisplayRef.current.className);
          setTimeout(() => {
            div[0].scrollTop = 10;
            setIsCallApi(false);
          }, 500);
        }
      } else {
        setChatt({data: [], loading: false});
        setallMsg({data: [], loading: false});
        setIsCallApi(false);
      }});

    } catch(error){
      // console.log(error)
    }
    
    if(!showPayment){
      let div = document.getElementsByClassName(msgDisplayRef.current.className);
      setTimeout(() => {
        div[0].scrollTop = 10;
      }, 500);
    }
  //  setIsCallApi(false);
    }
  }


  useEffect(() => {
    const fetchData = async () => {
      //dispatch(setCurrentEventChat({ currentEvent: defaultEventType }));
      const result = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}eventType/specify?userId=${userId}&supId=${supplierId}&categoryId=${categoryId}`);
      setEvents({data:result.data,loading:false});
      if(result.data !== null){
        result.data.map((item)=> {
          if(item.id === Number(eventTypeId)){
            dispatch(setCurrentEventChat({ currentEvent: item }));
          } 
          return item
        })
      }
     };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function backToAllChats(){
    if(currentRole === 'USER'){ 
      navigate('/personal/chats');
    } else if(currentRole !== 'USER'){
      navigate('/personal-supplier/chats');
    }
  }

  function openFile(file){
    window.open(file);
  }

  const submitMessage = async () => {
    sendMessage();
    // let data = defaultValues;
    // data.message = message;
    // const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}chats/send`,
    //   data
    // );
    // if(result){
    //   setMessage('');
    //   getNewMessage();
    // }
  };

  const onChangeOption = async (option) =>{
    setfirstLoading(true);
    if(option.id !== currentEvent.id){
    dispatch(setCurrentEventChat({ currentEvent: option }));
    seteventTypeId(option.id);
    const uniqueId = await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}chats/chat?userId=${userId}&supId=${supplierId}&categoryId=${categoryId}&eventTypeId=${option.id}&checkListId=${checkListId ? +checkListId :currentChecklist?.checkListId || 0}`);
    setchatUniqueId(uniqueId.data?.chatId);
    if(uniqueId){
      setIsCallApi(true);
      setChatt({data: [], loading: true});
      firstLoad.current = true;
      //writeUserData(uniqueId.data);
      try {
        let c = 10
        const db = getDatabase();
        const starCountRef = query(ref(db,  'chats/' + uniqueId.data?.chatId +'/messages'),limitToLast(10) ); 
        const request = starCountRef;
        onValue(request, (snapshot) => {
        //setglobalData(snapshot.val());
        let data = snapshot.val();
        data = Object.keys(data).map(y => {
          return {
            key: y,
            ...data[y]
          }
        })
        //console.log('dataa',snapshot.val());
        setallMsg({data: [], loading: true});
        setIsCallApi(true);
        if(data){
          setallMsg({data: Object.values(data).reverse(), loading: false});
        //  setChatt({data: data.reverse(), loading: false});
          //setChatt({data: [], loading: true});
          if(c === 10){
            // let lastMsg = Object.values(data).reverse();
            // if(lastMsg[0].sender !== currentRole && !lastMsg[0].isRead){
            //   lastMsg[0].isRead = true; 
            //   update(query(ref(db,  'chats/' + chatUniqueId +'/messages/'+ Object.keys(data).reverse()[0])), lastMsg[0]);
            // }
            //   loadByNewEventType(0, Object.values(data.reverse()) );
            if(data.length > 0){
              let key = data;
              setlastKey(key[0].key);
            }
            loadByNewEventType(1, Object.values(data) );
            let newState = flag
            setflag(newState++);
          }
        } else {
          setChatt({data: [], loading: false});
          setallMsg({data: [], loading: false});
          setIsCallApi(false);
        }
    });
    
    } catch(error){
      dispatch(
        setErr({
          message: error,
          code: 400,
      }) )
    }
    }
    if(currentRole === 'USER'){   
      const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}contract/user/check?eventTypeId=${option.id}&categoryId=${categoryId}&supplierId=${supplierId}&checkListId=${checkListId ? +checkListId :currentChecklist?.checkListId || 0}`);
      setclickContracts(result.data.updateAllowed);
    } else if(currentRole !== 'USER'){
      const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}contract/supplier/check?eventTypeId=${option.id}&categoryId=${categoryId}&userId=${userId}&supId=${supplierId}&checkListId=${checkListId ? +checkListId : currentChecklist?.checkListId || 0}`);
      setclickContracts(result.data.updateAllowed);
    }
   // setChatt({data:[], loading: true})
   // setIsCallApi(true);
    // setPaginateConfig({
    //   currentPage: 0,
    //   itemsPerPage: 10,
    //   totalItems: 0,
    //   totalPages: 0
    // })
    changeServices(option.id);
   // getfirstChatSupplier(0,option.id);
  }
  };

  const sendZapierUserFirstMessage = async() => {
    await axios.post(`${process.env.REACT_APP_API_PATH}whatsapp?key=${process.env.REACT_APP_ZAPIER_CONTROLLER_KEY}&userId=${userId}&supId=${supplierId}&triggerType=${'FIRST_MSG_FROM_CUSTOMER_TO_PROVIDER'}&chatUrl=${window.location.href}`)
    .then((response) => {
       // console.log('Zapier request sent successfully:', response.data);
      })
      .catch((error) => {
      //  console.error('Error sending Zapier request:', error);
      });
  }

  const sendZapierUserWantsCall = async() => {
    await axios.post(`${process.env.REACT_APP_API_PATH}whatsapp?key=${process.env.REACT_APP_ZAPIER_CONTROLLER_KEY}&userId=${userId}&supId=${supplierId}&triggerType=${'CUSTOMER_WANNA_VIDEO'}&chatUrl=${window.location.href}`)
    .then((response) => {
      })
      .catch((error) => {
      });
  }

  const sendZapierUserWantContract = async() => {
    await axios.post(`${process.env.REACT_APP_API_PATH}whatsapp?key=${process.env.REACT_APP_ZAPIER_CONTROLLER_KEY}&userId=${userId}&supId=${supplierId}&triggerType=${'CUSTOMER_INTERESTED_CONTRACT'}&chatUrl=${window.location.href}`)
    .then((response) => {
      })
      .catch((error) => {
      });
  }

  // const sendZapierSupplierFirstMessage = async() => {
  //   await axiosPrivate.get(`${process.env.REACT_APP_API_PATH}zapier?key=${process.env.REACT_APP_ZAPIER_CONTROLLER_KEY}&userId=${userId}&supId=${supplierId}&triggerType=${'FIRST_MSG_FROM_PROVIDER_TO_CUSTOMER'}&chatUrl=${window.location.href}`)
  //   .then((response) => {
  //     })
  //     .catch((error) => {
  //     });
  // }

  const loadByNewEventType = (count, value) =>{
    setChatt({data:[],loading:true});
    setChatt({data: value, loading: false});
    //setglobalDataSet({data:[],loading:true})
    // let newData = value.sort((x,y)=>y.timestamp - x.timestamp)
    if(count === 1){
      setglobalDataSet({data: value, loading: false})
      setglobalData({data: value, loading: false})
    }
    setTimeout(() => {
      let div = document.getElementsByClassName(msgDisplayRef.current.className);
      div[0].scrollTop = div[0].scrollHeight;
      setIsCallApi(false);
    }, 500);
    // setTimeout(() => {
    // }, 500);
    }

  const navigateSuppliers = () => {
    dispatch(
      setSelectedCategoryId({
        id: categoryId || 0,
        name: "",
      })
    );
  dispatch(setSelectedEventTypeId(eventTypeId));
  let eventType = {id: eventTypeId, name: currentEvent.name};
  dispatch(setCurrentEvent({currentEvent: eventType}));
    navigate(`/supplier/${supplierId}`)
  };

  const onChange = (event) => {
    setOpenMeeting(false);
    let data = defaultValues;
    setcustomTypeMessage(true);
    data.message = JSON.stringify(event);
    data.fromUserType = 'SYSTEM_INVITE_APPROVE_SUPPLIER';
    data.toUserType = 'USER';
    data.sender = currentRole;
    data.receiver = 'USER';
    data.isRead = false;
    data.timestamp = Date.now();
    data.fileUrl = '';
    if(currentRole !== 'LABEL'){  
      data.name = userName
    } else{
      data.name = supName
    }
    data.isChecked = false;
    const db = getDatabase();
  try{
    const postListRef = ref(db, '/chats/' + chatUniqueId + '/messages');
    const newPostRef = push(postListRef);
    set(newPostRef, data);
    setMessage('');
  } catch (error){
    dispatch(
      setErr({
        message: error,
        code: 400,
    }) )
  }
 //   getNewMessage();
  }
 let notificationId1;
  const checkPayment = async (id) =>{
    let checkId = contractId !== 0 ? contractId : id;
    let count = 0;
    const result = axiosPrivate.get(`${process.env.REACT_APP_API_PATH}contract/status/pay?contractId=${checkId}&checkListId=${checkListId ? +checkListId :currentChecklist?.checkListId || 0}`);
      result.then(res=>{
        if(res.data.status === 'PAYED'){
        setIsPaySuccess(true)
        sendAnalitics2(checkId);
        setopenPayment(true)
        checkPaymentFunc.current = false;
        }
      })
    notificationId1 = setInterval(() => {
  if(checkPaymentFunc.current){
    const result = axiosPrivate.get(`${process.env.REACT_APP_API_PATH}contract/status/pay?contractId=${checkId}&checkListId=${checkListId ? +checkListId :currentChecklist?.checkListId || 0}`);
      result.then(res=>{
        if(res){
        //  setopenPayment(true);
        //  checkPaymentFunc.current = false;
        if(count <= 60){
          count++; 
          if(res.data.status === 'PAYED'){
            setIsPaySuccess(true)
            sendAnalitics2(checkId);
            setopenPayment(true);
            checkPaymentFunc.current = false;
            checkPaymentUserButton(contractId);
            clearInterval(notificationId1);

          } else if(res.data.status === 'UN_PAYED'){
            setIsPaySuccess(true)
            setstatusUnPaid(true);
            clearInterval(notificationId1);
						setshowPayment(false);
          }
        } else{
          checkPaymentFunc.current = false;
          checkPaymentUserButton(contractId);
          setshowPayment(false);
          setopenPayment(false);
          clearInterval(notificationId1);
        }
        if(res.data.status === 'PAYED'){
          setIsPaySuccess(true)
          sendAnalitics2(checkId);
          setopenPayment(true);
          checkPaymentFunc.current = false;
          checkPaymentUserButton(contractId);
          clearInterval(notificationId1);
        } else if(res.data.status === 'UN_PAYED'){
          setIsPaySuccess(true)
          setstatusUnPaid(true);
          clearInterval(notificationId1);
          setshowPayment(false);
        }
        } else{
         // clearInterval(notificationId1);
        }
      }).catch(
        ()=>{clearInterval(notificationId1);
        }
      )
  } else {
    clearInterval(notificationId1);
  }
    }, 30000);
    return ()=>{clearInterval(notificationId1)}

  }

  useEffect(() => {
    return ()=>{clearInterval(notificationId1);};
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
  const schemaUser = yup
  .object()
  .shape({
    userIdNumber:yup
    .string(),
    additionalUserIdNumber:( secondId ? yup
      .string() //name required
      : yup
      .string()),
      city: yup
      .string()
      .min(2, 'לפחות 2 אותיות')  // min char 2
      .max(5000, 'מקסימום 5000 ספרות') // MAX_CHAR_5000
      .required('הכנס שם משתמש'), //name required
      additionalCity: ( secondAddress ? yup
      .string()
      .min(2, 'לפחות 2 אותיות')  // min char 2
      .max(5000, 'מקסימום 5000 ספרות') // MAX_CHAR_5000
      .required('הכנס שם משתמש') //name required
      :
      yup
      .string()
      ),
    address: yup
    .string()
    .min(2, 'לפחות 2 אותיות')  // min char 2
    .max(5000, 'מקסימום 5000 ספרות') // MAX_CHAR_5000
    .required('הכנס שם משתמש'), //name required
    additionalAddress:  ( secondAddress ? yup
    .string()
    .min(2, 'לפחות 2 אותיות')  // min char 2
    .max(5000, 'מקסימום 5000 ספרות') // MAX_CHAR_5000
    .required('הכנס שם משתמש') //name required
    :
    yup
    .string()
    )
  }).required();

  const schemaSupplier = yup
  .object()
  .shape({
    discount: yup
    .string()
    .max(100, '')
  }).required();

  const disabledayoncalendar = async() => {
    try {
      const res = await axiosPrivate.post(`/calendar/google/contract/event?contractId=${contractId}&date=${eventDate}&supId=${supplierId}&blocked=true`);
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
          
          setsecondDialog(false);
      }
      
    } catch (error) {
    }
  }

  const samyDisabledayoncalendar = async() => {
    try {
      const res = await axiosPrivate.post(`/calendar/google/contract/event?contractId=${contractId}&date=${eventDate}&supId=${supplierId}&blocked=false`);
      if (res?.error) {
        dispatch(
          setErr({
            message: res?.payload?.response?.data?.message,
            code: res?.payload?.response?.data?.code,
          })
        );
      } else {
          setsecondDialog(false);
      }
    } catch (error) {}
  }

  const checkService = (data) => {
    let result = JSON.parse(data);
    return (
      <>
        <MetaFavicon />
        <div>
          <div className={styles.msgTitle}>הצעת המחיר שלי עבורך היא:</div>
          <div className={styles.msgBlock}>
          {result.services.map((item, index) => { 
            return (
            <div key={index} className={styles.msgContent}>
              <div>{item.serviceName}</div> &nbsp;-&nbsp;
              <div>{item.price}</div>
            </div>
            ) }) }
          </div>
            <div><hr className={styles.msgLine}></hr></div>
            {/* {!!discount && <div className={styles.msgAllSum}>
              <div>{"הנחה"}</div>
              <div  style={{marginBottom: '10px'}}> 
                <div className={styles.supplierAllPricePopup}> &nbsp;&nbsp;{result.discount} 
                <img src={shekelSymbol} alt="shekel" className={styles.shekelSymbol}/> 
                </div>
              </div>
            </div>} */}
            <div className={styles.msgAllSum}>
              <div>סך הכל</div>
              <div  style={{marginBottom: '10px'}}> 
                <div className={styles.supplierAllPricePopup}> &nbsp;&nbsp;{result.totalPrice} 
                <img src={shekelSymbol} alt="shekel" className={styles.shekelSymbol}/> 
                </div>
              </div>
            </div>
            {result.discountPrice > 0 && <div className={styles.msgDiscountSum}>
              <div className={styles.msgblockSum}>מחיר סופי לאחר הנחה</div>
              <div className={styles.msgblockSum}> 
                <div className={styles.supplierAllPriceMsg
                }> &nbsp;&nbsp;{result.discountPrice} 
                <img src={shekelSymbol} alt="shekel" className={styles.shekelSymbol}/> 
                </div>
              </div>
            </div>}
        </div>
      </>
    )
  }
  
  const checkInvite = (data,messageTime, id) => {
    let result = JSON.parse(data);
    return (
      <>
        <div>
          <div className={styles.inviteTitle}>הזמנה לשיחת וידאו עם {result.supName} </div>
          <div className={styles.inviteBlock}>תאריך: {result.date} </div>
          <div className={styles.inviteBlock}> שעה: {dayjs(result.start.split('[')[0]).format("HH:mm")}-{dayjs(result.end.split('[')[0]).format("HH:mm")} </div>
          <div className={styles.inviteLinkBlock}>
            <div className={styles.inviteLinkTitle}>קישור לשיחה:</div> &nbsp;
            <div className={styles.inviteLink} onClick={()=>{openLink(result.link);}} >{result.link}</div>
          </div>
          {!result.isChecked && currentRole === 'USER' && <div className={styles.buttonsLinkBlock}>
            <button onClick={()=>{acceptInvite(true, messageTime, id);}} className={styles.buttonLinkYes}>אשר</button>
            <button onClick={()=>{acceptInvite(false, messageTime, id);}} className={styles.buttonLinkNo}>דחה</button>
          </div>}
        </div>
      </>
    )
  }

  const openLink = (link) => {
    window.open(link,'_blank')
  }

  const acceptInvite = async(invite, time, id) => {
   const result = await axiosPrivate.post(`${process.env.REACT_APP_API_PATH}chats/going/to/google/meet?isGoing=${invite}&messageTime=${time}&categoryId=${categoryId}&eventTypeId=${eventTypeId}&supId=${supplierId}&checkListId=${checkListId ? +checkListId :currentChecklist?.checkListId || 0}`);
   if(result){
    let data = defaultValues;
    setcustomTypeMessage(true);
    let messageType = invite ? 'הלקוח אישר את מועד שיחת הוידאו' : 'הלקוח דחה את מועד שיחת הוידאו' ;
    data.message = messageType;
    data.fromUserType = 'USER_GOING_OR_NOT_GOOGLE_MEET';
    data.toUserType = 'USER';
    data.sender = currentRole;
    data.receiver = 'USER';
    data.isRead = false;
    data.timestamp = Date.now();
    data.fileUrl = '';
    if(currentRole !== 'LABEL'){  
      data.name = userName
    } else{
      data.name = supName
    }
    const db = getDatabase(); 

    let lastMsg = '';
     let items = chatt.data.map((item)=>{
        if(item.key === id){
          let value = JSON.parse(item.message);
          value.isChecked = true;
          item.message = JSON.stringify(value);
          lastMsg = item;
        }
         return item;
      })
      if(items){}
      let key = lastMsg.key;
      delete(lastMsg.key);
    update(query(ref(db,  'chats/' + chatUniqueId +'/messages/'+ key)), lastMsg);
  try{
    const postListRef = ref(db, '/chats/' + chatUniqueId + '/messages');
    const newPostRef = push(postListRef);
    set(newPostRef, data);
   } catch (error){
    dispatch(
      setErr({
        message: error,
        code: 400,
    }) )
   }
  }
  }

 
  const openContractDialog = (e) => {
    e.preventDefault()
    setvalueIds({categoryId:categoryId,userId:userId,supplierId:supplierId,eventTypeId:eventTypeId,name:name.userName,contractId:contractId});
    setopenContract(true);
  }
  const schema = currentRole === 'USER' ? schemaUser : schemaSupplier;

  const defaultUserContractForm = {
    userIdNumber: '0',
    additionalUserIdNumber: '0',
    city: "",
    additionalCity: "",
    address: "",
    additionalAddress: "",
  };
  const defaultSupplierContractForm = {
    discount: '',
  };

  const defaultContractForm = currentRole === 'USER' ? defaultUserContractForm : defaultSupplierContractForm;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultContractForm,
    resolver: yupResolver(schema),
  });

  function closepopup(){
    checkSupplierContractButton(contractId);
    checkSupplierButton();
    changeServices(eventTypeId)
  }

  function closePaymentPopup(){
    checkPaymentUserButton(contractId);
    setshowPayment(false);
    setopenPayment(false);
  }

  function convertToTwoCharacter(numb) {
    return numb.toString().length === 1 ? "0" + numb : numb.toString();
  }

function convertDate(date){
    const time = new Date(date);
    let month = time.getMonth() + 1;
    let day = time.getDate();
    let dayString = convertToTwoCharacter(day);
    let monthString = convertToTwoCharacter(month);
    let dateFull = dayString + '.' + monthString + '.' + time.getFullYear();
    return dateFull
  }
  const handleQuatationButton = () =>{
    handleClickOpenSecond(false)
    setIsOpenQuatation(true)
  }

  const sendMsgByEnter = (val,keyCode) =>{
    if (keyCode === 13) {
      sendMessage();
    }
  }
  return (
    <>
    { !showPayment ?
    <>


    <Popup isOpen={openContract} value={{contractId: contractId, categoryId:categoryId, userId:userId,supplierId:supplierId,eventTypeId:eventTypeId,name:name.userName, discount: (currSum||discount)}} setIsOpen={setopenContract} closePopup={closepopup} />
    <div className={styles.mobileTitle}>צ'אט</div>
    <div className={styles.desctop}>
    {/* { currentRole === 'USER' && <div className={styles.eventTypeBlock}>
      <div className={styles.eventLabel}>בחר\י אירוע מתוך רשימת האירועים שלך</div>
      <div className={styles.eventMobileLabel}>בחר\י אירוע מתוך רשימת האירועים</div>
    <div className={styles.select}>
    <Select
      placeholder={" "}
      fontFamilyHeader={"Heebo-Light"}
      backgroundColor={"#EEE9E5"}
      options={allEvents.data}
      onChangeOption={(e)=>{onChangeOption(e);console.log('click')}}
      selectedOption={currentEvent || defaultEventType}
    />
  </div>
  </div> } */}
    <div className={styles.menu}>
        { currentRole === 'USER' && <div className={styles.menuDesctop}>{name?.supName}</div>}
        { currentRole !== 'USER' && <div className={styles.menuDesctop}>{name?.userName}</div>}
        { currentRole === 'USER' && <div className={styles.menuMobile}>{name?.supName}</div> }
        <div onClick={(e)=>{backToAllChats()}} style={{cursor: 'pointer'}} className={styles.menuMobile}>חזרה</div>
        <div onClick={(e)=>{backToAllChats()}} style={{cursor: 'pointer'}} className={styles.menuDesctop}>חזרה לכל ההודעות</div>
        {/* <div className={styles.item}>שעון (רץ אחורה)</div> */}
    </div>
    
    <div className={styles.chatBlocks}>
   
    { currentRole === 'USER' && <div className={styles.rightBlock}>
      <div>
          <div className={styles.eventName}>{currentEvent.name}</div>
          <div>
          
          {dataForUser.eventDate!==''&& dataForUser.eventDate!==null&&<><div className={styles.supplierDate}>תאריך האירוע</div>
          <div className={styles.supplierDateText}>{
            dayjs(dataForUser.eventDate).format("DD/MM/YYYY")
          // dataForUser.eventDate.split('T')[0]
          }</div>
          </>}
          {dataForUser.eventDate!==''&& dataForUser.eventDate!==null&&<>
          <div className={styles.supplierPlace}>מקום האירוע</div>
          <div className={styles.supplierPlaceText}>{dataForUser.venue}</div></>}
          {dataForUser.eventDate!==''&& dataForUser.eventDate!==null&&<>
          <div className={styles.supplierPlace}>כמות אורחים</div>
          <div className={styles.supplierPlaceText}>{dataForUser.amountOfGuests}</div></>}
        </div>
          <div className={styles.buttonsBlock}>
            {/*<button className={styles.buttonVideo} onClick={() => handleClickOpen()}>שלח לי חוזה</button>
            <button className={styles.buttonContact} onClick={()=>{sendCustomMessage(1);}}>בקש שיחת וידאו</button>
          */}
          </div>
          
          <div className={styles.textareaWrapper}>
            <textarea value={textInTextarea} readOnly className={styles.textarea}/>
          </div>
          <hr className={styles.line}></hr>
          <div className={styles.linkBlock}>
            <div className={styles.serviceName}>תחום השירות ומחירים</div>
            <div onClick={(e)=>{navigateSuppliers()}} className={styles.supplierLink}>עריכה</div>
          </div>
          <div className={styles.services}>
          {!services.loading && services.data.map((item, index) => { 
          return ( 
          <div key={index} className={styles.servicesBlock} >
          {item.count <= 0 && <div className={styles.service}>
            <div className={styles.serviceItem}>{item.name}</div>
            <div style={{direction:'ltr', textAlign: "right"}} className={styles.serviceSum}>
            <img src={shekelSymbol} alt="shekel" className={styles.shekelSymbol}
              />  {numberWithCommas(item.price)} 
            </div>
            <div className={styles.serviceCount}>{item.count} </div>
          </div> }
          {item.count > 0 && <div className={styles.serviceChoosed}>
          <div className={styles.serviceItem}>{item.name}</div>
            <div style={{direction:'ltr', textAlign: "right"}} className={styles.serviceSum}>
              <img src={shekelSymbol} alt="shekel" className={styles.shekelSymbol}
              /> {numberWithCommas(item.price)}
            </div>
            <div className={styles.serviceCount}>{item.count} </div>
          </div> }
          </div>
          )}) }
            </div>
          </div>
          <div>
            <hr className={styles.line}></hr>
            {!!discount && <div className={styles.result}> 
              <div className={styles.serviceAllItem}>{"הנחה"}</div>
              <div className={styles.serviceAllSum}>
                {numberWithCommas(discount)} <img src={shekelSymbol} alt="shekel" className={styles.shekelSymbol}/>
              </div>
            </div>}
            <div className={styles.result}> 
              <div className={styles.serviceAllItem}>סך הכל</div>
              <div className={styles.serviceAllSum}>
                {numberWithCommas(allSum)} <img src={shekelSymbol} alt="shekel" className={styles.shekelSymbol}/>
              </div>
              {/* <div className={styles.serviceCount}></div> */}
            </div>
            <button onClick={(e)=> getActionForButton()} style={{cursor:'pointer'}} className={styles.paymentButton}>{textInButton}</button>
          </div>
      </div> }
      
    { currentRole !== 'USER' && <div className={styles.supplierRightBlock}>
      <div className={styles.firstRightBlock}>
      <div className={styles.suppliersEventType}>סוג האירוע</div>
      <div className={styles.supplierSwiper}>
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={15}
        centeredSlides={false}
      >
          {!allEvents.loading &&
              allEvents.data.map(item => {
                  return (
                      <SwiperSlide className={currentEvent.id === item.id ? styles.slideWrapperActive : styles.slideWrapper} key={item.id}>
                          <div onClick={(e)=>{onChangeOption(item);}} className={styles.swiperText}>{item.name}</div>
                      </SwiperSlide>
                  )
              })
          }
      </Swiper>
      </div>
      <div>

        {dataForUser.eventDate!==''&& dataForUser.eventDate!==null&&<><div className={styles.supplierDate}>תאריך האירוע</div>
        <div className={styles.supplierDateText}>{
          dayjs(dataForUser.eventDate).format("DD/MM/YYYY")
        // dataForUser.eventDate.split('T')[0]
        }</div>
        </>}
        {dataForUser.venue!==''&& dataForUser.venue!==null&&<>
        <div className={styles.supplierPlace}>מקום האירוע</div>
        <div className={styles.supplierPlaceText}>{dataForUser.venue}</div></>}
        {dataForUser.amountOfGuests!==0&& dataForUser.amountOfGuests!==null&&<>
        <div className={styles.supplierPlace}>כמות אורחים</div>
        <div className={styles.supplierPlaceText}>{dataForUser.amountOfGuests}</div></>}
      </div>
      <div className={styles.supplierButtons}>
        <button 
          onClick={() => (editPermission && !(contractStatus.isPaid|| contractStatus.isSent|| contractStatus.isSigned) ? handleQuatationButton() : '')} 
          disabled={contractStatus.isPaid|| contractStatus.isSent|| contractStatus.isSigned} 
          style={{cursor: (editPermission && !(contractStatus.isPaid|| contractStatus.isSent|| contractStatus.isSigned) ? 'pointer' : 'default')}} 
          className={!(contractStatus.isPaid|| contractStatus.isSent|| contractStatus.isSigned) ?styles.supplierButton :styles.supplierButtonDisabled}>שלח הצעת מחיר</button>
        {/* <button className={styles.supplierButton} style={{cursor: (editPermission ? 'pointer' : 'default')}} onClick={() => (editPermission ? handleClickMeeting() : '')}>קבע שיחת וידאו</button> */}
      </div>
        </div>
        <div className={styles.secondRightBlock}>
        <div className={styles.supplierServiceTitle}>בקשות הלקוח</div>
        <div className={styles.supplierServices}>
        {!services.loading && services.data.map((item, index) => { 
        return ( 
          <div key={index}>{item.count > 0 && <div  className={styles.supplierServiceDetails}>
            <div>{item.name}</div>&nbsp;-&nbsp;<div>{numberWithCommas(item.price * item.count)} <img src={shekelSymbol} alt="shekel" className={styles.shekelSymbol}/> </div>
          </div> }</div>
        )})
      }
        </div>
        <hr className={styles.supplierServiceLine}></hr>
        {!!discount && <div className={styles.supplierServiceAllSum}> 
          <div className={styles.supplierAllPriceText}>{"הנחה"}</div>
          <div className={styles.supplierAllPricePopup}>{numberWithCommas(discount)} &nbsp;
          <img src={shekelSymbol} alt="shekel" className={styles.shekelSymbol}/> 
          </div>
        </div>}
        <div className={styles.supplierServiceAllSum}> 
          <div className={styles.supplierAllPriceText}>סך הכל</div>
          <div className={styles.supplierAllPricePopup}>{numberWithCommas(allSum)} &nbsp;
          <img src={shekelSymbol} alt="shekel" className={styles.shekelSymbol}/> 
          </div>
        </div>
        {dataForUser.prePayment!==0&& dataForUser.prePayment!==null&& <div className={styles.supplierServiceAllSum}> 
          <div className={styles.supplierAllPriceText}>תשלום מקדמה</div>
          <div className={styles.supplierAllPricePopup}>{numberWithCommas(dataForUser.prePayment)} &nbsp;
          <img src={shekelSymbol} alt="shekel" className={styles.shekelSymbol}/> 
          </div>
        </div>}
        <Tooltip title="החוגג טרם חתםוזה" open={false} onClose={handleCloseTooltip} onOpen={handleOpenTooltip}>
          <button 
            style={{cursor: ((editPermission || supplierContractButton) ? 'pointer' : 'default')}} 
            onClick={() => editPermission  ? getActionForButtonSupplier() : ''}
            className={styles.supplierPayButton}> {textInSuppButton}
          </button>
        </Tooltip>       
       </div>
      </div> }
      
      <div className={styles.leftBlock}>
      {/* <div className={styles.messages}> */}
      <div ref={msgDisplayRef} className={styles.chatBlock}  onScroll={(e) => scrollEvent(e)}>
      {!chatt.loading && chatt?.data && chatt?.data?.map((item, index) => {
        return (
        <div key={index} >
          {/* key={item.id} */}
        {(currentRole === item?.fromUserType || (currentRole === 'LABEL' && !item?.suppluer && item?.fromUserType !== 'SYSTEM_USER' &&
          item?.fromUserType !== 'SYSTEM_SUPPLIER' && item?.fromUserType !== 'SYSTEM_INVITE_REQUEST_USER' && 
          item?.fromUserType !== 'SYSTEM_INVITE_APPROVE_SUPPLIER' && item.fromUserType !== 'FILE_UPLOAD' && item?.fromUserType !== 'USER_GOING_OR_NOT_GOOGLE_MEET' && item?.fromUserType !== 'USER' && item?.message)) && <div className={styles.UserBlock}>
          <div className={styles.userName}>אני</div>
          <div className={styles.messageUser}>{item.message}</div>
        </div>}
        {currentRole !== item?.fromUserType && !item?.suppluer && item?.fromUserType !== 'SYSTEM_USER' &&
          item?.fromUserType !== 'SYSTEM_SUPPLIER' && item?.fromUserType !== 'SYSTEM_INVITE_REQUEST_USER' && 
          item?.fromUserType !== 'SYSTEM_INVITE_APPROVE_SUPPLIER' && item.fromUserType !== 'FILE_UPLOAD' && item?.fromUserType !== 'USER_GOING_OR_NOT_GOOGLE_MEET' && item?.message && currentRole !== 'LABEL' &&
        <div className={styles.messageBlock}>
          <div className={styles.senderName}>{item.name}</div>
          <div className={styles.message}>{item.message}</div>
        </div> }
        {item?.fromUserType === 'USER' && !item?.suppluer && item?.fromUserType !== 'SYSTEM_USER' &&
          item?.fromUserType !== 'SYSTEM_SUPPLIER' && item?.fromUserType !== 'SYSTEM_INVITE_REQUEST_USER' && 
          item?.fromUserType !== 'SYSTEM_INVITE_APPROVE_SUPPLIER' && item.fromUserType !== 'FILE_UPLOAD' && item?.fromUserType !== 'USER_GOING_OR_NOT_GOOGLE_MEET' && item?.message && currentRole === 'LABEL' &&
        <div className={styles.messageBlock}>
          <div className={styles.senderName}>{item.name}</div>
          <div className={styles.message}>{item.message}</div>
        </div> }
        { item?.fromUserType === 'SYSTEM_USER' && <div className={styles.messageBlock}>
          <div className={styles.senderName}>מערכת</div>
          <div className={styles.message}>הבקשה נשלחה</div>
        </div> }
        { item?.fromUserType === 'SYSTEM_SUPPLIER' && <div className={styles.messageBlock}>
          <div className={styles.senderName}>מערכת</div>
          <div className={styles.message}>{checkService(item.message)}</div>
        </div> }
        { item.fromUserType === 'FILE_UPLOAD' && currentRole === 'USER' && <div className={styles.UserBlock}>
          <div className={styles.userName}>אני</div>
          <div style={{alignItems: 'center', display: 'flex', wordBreak: 'break-all'}} className={styles.messageUser}>
          <img onClick={(e)=>{openFile(item.fileUrl)}} alt="file_on_chat" style={{cursor: 'pointer'}} src={file_on_chat}  />
          {item.message}
          </div>
        </div> } 
        { item.fromUserType === 'FILE_UPLOAD' && currentRole !== 'USER' &&
        <div className={styles.messageBlock}>
         <div className={styles.senderName}>{item.name}</div>
         <div className={styles.message} style={{alignItems: 'center', display: 'flex', wordBreak: 'break-all'}}>
          <img onClick={(e)=>{openFile(item.fileUrl)}} alt="file_on_chat" style={{cursor: 'pointer'}} src={file_on_chat}  />
          {item.message}
         </div>
        </div>}
        { item?.fromUserType === 'SYSTEM_INVITE_APPROVE_SUPPLIER' && <div className={styles.messageBlock}>
          <div className={styles.senderName}>מערכת</div>
          <div className={styles.message} style={{alignItems: 'center', display: 'flex'}}>{checkInvite(item.message, item.messageTime, item.key)}</div>
        </div> }
        { item?.fromUserType === 'USER_GOING_OR_NOT_GOOGLE_MEET' && <div className={styles.messageBlock}>
          <div className={styles.senderName}>מערכת</div>
          <div className={styles.messageAcept}>{item.message}</div>
        </div> }
        { item?.fromUserType === 'SYSTEM_INVITE_REQUEST_USER' && <div className={styles.messageBlock}>
          <div className={styles.senderName}>מערכת</div>
          <div className={styles.message}>{item.message}</div>
        </div> }
        </div>
        )
         })
      }
      </div>
      {/* </div> */}
      { currentRole !== 'USER' && <div className={styles.buttonSend2}>
      <img alt="send"  onClick={(e) => currentRole === 'USER' ? submitMessage() :(editPermission ? submitMessage() : '')} style={{cursor: currentRole === 'USER' ? 'pointer' :(editPermission ? 'pointer' : 'default')}} src={send} className={styles.sendIcon} />
      <input onKeyDown={(e)=> currentRole === 'USER' ? sendMsgByEnter(e.target.value,e.keyCode) :(editPermission ? sendMsgByEnter(e.target.value,e.keyCode) : '')} onChange={(e)=>setMessage(e.target.value)} value={message} type="text" className={styles.sendMessage2}
        placeholder='רשום הודעה'/>
        <img alt="meet"  onClick={(e) => currentRole === 'USER' ? '' :(editPermission ? handleClickMeeting() : '')} style={{cursor: currentRole === 'USER' ? 'pointer' :(editPermission ? 'pointer' : 'default'),right:'32px'}} src={meet_desctop} className={styles.request_invite} />
      </div> }
      { currentRole === 'USER' && <div className={styles.buttonSend2}>
      <img alt="send"  onClick={(e) => currentRole === 'USER' ? submitMessage() :(editPermission ? submitMessage() : '')} style={{cursor: currentRole === 'USER' ? 'pointer' :(editPermission ? 'pointer' : 'default')}} src={send} className={styles.sendIcon} />
      <input onKeyDown={(e)=> currentRole === 'USER' ? sendMsgByEnter(e.target.value,e.keyCode) :(editPermission ? sendMsgByEnter(e.target.value,e.keyCode) : '')} onChange={(e)=>{setMessage(e.target.value);}} value={message} type="text" className={styles.sendMessage2}
        placeholder='רשום הודעה'/>
        <img alt="meet"  onClick={(e) => currentRole === 'USER' ? sendCustomMessage(1, true) :(editPermission ? sendCustomMessage(1, true) : '')} style={{cursor: currentRole === 'USER' ? 'pointer' :(editPermission ? 'pointer' : 'default')}} src={meet_desctop} className={styles.request_invite} />
        <Box
                              className={styles.loadContract}
                              component="label"
                            >
                              <img alt="file_upload" src={file_upload} className={styles.file_upload} />
                              <input
                                className={styles.uploadImg}
                                id="button-file"
                                name="logoFile"
                                type="file"
                                // accept="application/pdf"
                                onChange={(e) => {
                                  function readFileAsync(index = 0) {
                                    return new Promise((resolve, reject) => {
                                      const file = e.target.files[index];
                                      if (!file) {
                                        return;
                                      }
                                      const reader = new FileReader();

                                      reader.onload = () => {
                                        if (file?.size < 15728640) {
                                          resolve({
                                            id: file?.size || 911,
                                            name: file?.name,
                                            url: `data:${
                                              file.type
                                            };base64,${btoa(reader.result)}`,
                                            file: e.target.files[index],
                                          });
                                        } else {
                                        }
                                      };

                                      reader.onerror = reject;

                                      reader.readAsBinaryString(file);
                                    });
                                  }

                                  readFileAsync().then((item) => {
                                    uploadFile(
                                      item,
                                      item?.file?.name || ""
                                    );
                                    return item;
                                  });
                                  // addImage(await newImg, indexCell)
                                }}
                                hidden
                              />
                            </Box>
      </div> }
      </div>
    </div>
    </div>
    <div className={styles.mobileMenu}>
    { currentRole !== 'USER' && <div className={styles.suppliersEventType}>סוג האירוע</div> }
    { currentRole !== 'USER' && <div className={styles.supplierSwiper}>
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={15}
        centeredSlides={false}
      >
          {!allEvents.loading &&
              allEvents.data.map(item => {
                  return (
                      <SwiperSlide onClick={(e)=>{onChangeOption(item);}} className={currentEvent.id === item.id ? styles.slideWrapperActive : styles.slideWrapper} key={item.id}>
                          <div className={styles.swiperText}>{item.name}</div>
                      </SwiperSlide>
                  )
              })
          }
      </Swiper>
      </div> }
      <div className={styles.mobileSelect}>
        <div onClick={toggling} className={styles.selectName}>פרטי האירוע
          <img onClick={toggling}
            alt="arrow"
            src={isOpen ? arrowUp : arrow}
            className={styles.arrow}
          />
        <div style={{display: isOpen ? "block" : "none"}}  className={styles.firstPopup}> 
          <div className={styles.firstPopupTitle}>{currentEvent.name}</div>

          {dataForUser.eventDate!==''&& dataForUser.eventDate!==null&&<div className={styles.firstPopupTitle}>{
          dayjs(dataForUser.eventDate).format("DD/MM/YYYY")
          // dataForUser.eventDate.split('T')[0]

          }</div>}
          {dataForUser.venue!==''&& dataForUser.venue!==null&&<div className={styles.firstPopupTitle}>{dataForUser.venue}</div>}
          {dataForUser.amountOfGuests!==0&& dataForUser.amountOfGuests!==null&&<div className={styles.firstPopupTitle}>{dataForUser.amountOfGuests}</div>}
        </div>
        </div>
      </div>
      { currentRole === 'USER' && <div className={styles.textareaWrapper}>
          <textarea value={textInTextarea} readOnly className={styles.textarea}/>
      </div>}
      <div className={styles.mobileSelect}>
        <div onClick={togglingSecond} className={classNames(styles.selectName,styles.selectServiceame)}>תחום השירות ומחירים
        
          <img onClick={togglingSecond}
            alt="arrow"
            src={isOpenSecond ? arrowUp : arrow}
            className={styles.arrow}
          /><div style={{display: isOpenSecond ? "block" : "none"}}  className={styles.secondPopup}> 
        <div className={styles.services}>
        {!services.loading && services.data.map((item, index) => { 
        return ( 
        <div key={index} className={styles.servicesBlock} >
         {item.count <= 0 && <div className={styles.service}>
          <div className={styles.serviceItem}>{item.name}</div>
          <div className={styles.serviceSum}>
             {numberWithCommas(item.price)} <img src={shekelSymbol} alt="shekel" className={styles.shekelSymbol}
            />
          </div>
          <div className={styles.serviceCount}>{item.count} </div>
        </div> }
        {item.count > 0 && <div className={styles.serviceChoosed}>
        <div className={styles.serviceItem}>{item.name}</div>
          <div className={styles.serviceSum}>
             {numberWithCommas(item.price)} <img src={shekelSymbol} alt="shekel" className={styles.shekelSymbol}
            />
          </div>
          <div className={styles.serviceCount}>{item.count} </div>
        </div> }
        </div>
        )}) }
        </div>
          <hr className={styles.line}></hr>
          {!!discount && <div className={styles.result}> 
          <div className={styles.serviceAllItem}>{"הנחה"}</div>
          <div className={styles.serviceAllSum}>
          {numberWithCommas(discount)} <img src={shekelSymbol} alt="shekel" className={styles.shekelSymbol}
            />
          </div>
          </div>}
          <div className={styles.result}> 
          <div className={styles.serviceAllItem}>סך הכל</div>
          <div className={styles.serviceAllSum}>
          {numberWithCommas(allSum)} <img src={shekelSymbol} alt="shekel" className={styles.shekelSymbol}
            />
          </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    <div className={styles.mobile}>
    { currentRole !== 'USER' && <div className={styles.buttonSend3}>
      <img alt="send"  onClick={(e) => currentRole === 'USER' ? submitMessage() :(editPermission ? submitMessage() : '')} style={{cursor: currentRole === 'USER' ? 'pointer' :(editPermission ? 'pointer' : 'default')}} src={send} className={styles.sendIcon} />
      <input onKeyDown={(e)=> currentRole === 'USER' ? sendMsgByEnter(e.target.value,e.keyCode) :(editPermission ? sendMsgByEnter(e.target.value,e.keyCode) : '')}  onChange={(e)=>setMessage(e.target.value)} value={message} type="text" className={styles.sendMessage2}
        placeholder='רשום הודעה'/>
        <img alt="meet"  onClick={(e) => currentRole === 'USER' ? '' :(editPermission ? handleClickMeeting() : '')} style={{cursor: currentRole === 'USER' ? 'pointer' :(editPermission ? 'pointer' : 'default'),right:'41px'}} src={meet_desctop} className={styles.request_invite} />
      </div> }
      { currentRole === 'USER' && <div className={styles.buttonSend3}>
      <img alt="send"  onClick={(e) => currentRole === 'USER' ? submitMessage() :(editPermission ? submitMessage() : '')} style={{cursor: currentRole === 'USER' ? 'pointer' :(editPermission ? 'pointer' : 'default')}} src={send} className={styles.sendIcon2} />
      <input onKeyDown={(e)=> currentRole === 'USER' ? sendMsgByEnter(e.target.value,e.keyCode) :(editPermission ? sendMsgByEnter(e.target.value,e.keyCode) : '')}  onChange={(e)=>setMessage(e.target.value)} value={message} type="text" className={styles.sendMessage2}
        placeholder='רשום הודעה'/>
          <img alt="meet" src={meet_desctop} className={styles.request_invite} />
        <Box
                              className={styles.loadContract}
                              component="label"
                            >
                              <img alt="file_upload"  onClick={(e) => currentRole === 'USER' ? sendCustomMessage(1) :(editPermission ? sendCustomMessage(1) : '')} style={{cursor: currentRole === 'USER' ? 'pointer' :(editPermission ? 'pointer' : 'default')}} src={file_upload} className={styles.file_upload} />
                              <input
                                className={styles.uploadImg}
                                id="button-file"
                                name="logoFile"
                                type="file"
                                // accept="application/pdf"
                                onChange={(e) => {
                                  function readFileAsync(index = 0) {
                                    return new Promise((resolve, reject) => {
                                      const file = e.target.files[index];
                                      if (!file) {
                                        return;
                                      }
                                      const reader = new FileReader();

                                      reader.onload = () => {
                                        if (file?.size < 15728640) {
                                          resolve({
                                            id: file?.size || 911,
                                            name: file?.name,
                                            url: `data:${
                                              file.type
                                            };base64,${btoa(reader.result)}`,
                                            file: e.target.files[index],
                                          });
                                        } else {
                                        }
                                      };

                                      reader.onerror = reject;

                                      reader.readAsBinaryString(file);
                                    });
                                  }

                                  readFileAsync().then((item) => {
                                    uploadFile(
                                      item,
                                      item?.file?.name || ""
                                    );
                                    return item;
                                  });
                                  // addImage(await newImg, indexCell)
                                }}
                                hidden
                              />
                            </Box>
        <img alt="meet"  onClick={(e) => currentRole === 'USER' ? sendCustomMessage(1, true) :(editPermission ? sendCustomMessage(1, true) : '')} style={{cursor: currentRole === 'USER' ? 'pointer' :(editPermission ? 'pointer' : 'default')}} src={meet_mobile
        } className={styles.request_invite} />
      </div> }
    { currentRole === 'USER' && <div className={styles.buttonsBlock}>
           {/*<button className={styles.buttonVideo} onClick={() => handleClickOpen()}>שלח לי חוזה</button>
           <button className={styles.buttonContact}  onClick={()=>{sendCustomMessage(1);}}>בקש שיחת וידאו</button> */}
             <button onClick={(e)=> getActionForButton()} style={{cursor:'pointer'}} className={styles.paymentButton}>{textInButton}</button>
         
    </div> }
    { currentRole === 'USER' && <hr className={styles.buttonContactLine}></hr> }
    { currentRole !== 'USER' && <div className={styles.supplierButtons}>
        {/* <button  onClick={() => (editPermission ? handleQuatationButton() : '')} style={{cursor: (editPermission ? 'pointer' : 'default')}} className={styles.supplierButton}>שלח הצעת מחיר</button> */}
        <button  style={{cursor: ((editPermission || supplierContractButton) ? 'pointer' : 'default')}} 
            onClick={() => editPermission  ? getActionForButtonSupplier() : ''} className={styles.paymentButton}>{textInSuppButton}</button>
        {/* <button className={styles.supplierButton} onClick={() => (editPermission ? handleClickMeeting() : '')}  style={{cursor: (editPermission ? 'pointer' : 'default')}}>קבע שיחת וידאו</button> */}
        <hr className={styles.supplierLine}></hr>
      </div> }
    <hr className={styles.mobileLineChat}></hr>
      <div ref={msgDisplayRef} className={styles.chatBlock}  onScroll={(e) => scrollEvent(e)}>
      {!chatt.loading && chatt.data.map((item, index) => { 
        return (
        <div key={index} >
        {currentRole === item.fromUserType && <div className={styles.UserBlock}>
          <div className={styles.userName}>אני</div>
          <div className={styles.messageUser}>{item.message}</div>
        </div>}
        {currentRole !== item.fromUserType && !item.suppluer && item.fromUserType !== 'SYSTEM_USER' &&
          item.fromUserType !== 'SYSTEM_SUPPLIER' && item.fromUserType !== 'SYSTEM_INVITE_REQUEST_USER' && 
          item.fromUserType !== 'USER_GOING_OR_NOT_GOOGLE_MEET' && item.fromUserType !== 'FILE_UPLOAD' && item.fromUserType !== 'SYSTEM_INVITE_APPROVE_SUPPLIER' && 
        <div className={styles.messageBlock}>
          <div className={styles.senderName}>{item.name}</div>
          <div className={styles.message}>{item.message}</div>
        </div> }
        { item.fromUserType === 'SYSTEM_USER' && <div className={styles.messageBlock}>
          <div className={styles.senderName}>מערכת</div>
          <div className={styles.message}>הבקשה נשלחה</div>
        </div> }
        { item.fromUserType === 'SYSTEM_SUPPLIER' && <div className={styles.messageBlock}>
          <div className={styles.senderName}>מערכת</div>
          <div className={styles.message}>{checkService(item.message)}</div>
        </div> }
        { item.fromUserType === 'FILE_UPLOAD' && currentRole === 'USER' && <div className={styles.UserBlock}>
          <div className={styles.userName}>אני</div>
          <div className={styles.messageUser} style={{alignItems: 'center', display: 'flex', wordBreak: 'break-all'}}>
           <img onClick={(e)=>{openFile(item.fileUrl)}} alt="file_on_chat" style={{cursor: 'pointer'}} src={file_on_chat}  />
           {item.message}
          </div>
        </div> } 
        { item.fromUserType === 'FILE_UPLOAD' && currentRole !== 'USER' &&
        <div className={styles.messageBlock}>
         <div className={styles.senderName}>{item.name}</div>
         <div className={styles.message} style={{alignItems: 'center', display: 'flex', wordBreak: 'break-all'}}>
          <img onClick={(e)=>{openFile(item.fileUrl)}} alt="file_on_chat" style={{cursor: 'pointer'}} src={file_on_chat}  />
          {item.message}
         </div>
        </div>}
        { item.fromUserType === 'SYSTEM_INVITE_APPROVE_SUPPLIER' && <div className={styles.messageBlock}>
          <div className={styles.senderName}>מערכת</div>
          <div className={styles.message}>{checkInvite(item.message, item.messageTime, item.key)}</div>
        </div> }
        { item.fromUserType === 'USER_GOING_OR_NOT_GOOGLE_MEET' && <div className={styles.messageBlock}>
          <div className={styles.senderName}>מערכת</div>
          <div className={styles.messageAcept}>{item.message}</div>
        </div> }
        { item.fromUserType === 'SYSTEM_INVITE_REQUEST_USER' && <div className={styles.messageBlock}>
          <div className={styles.senderName}>מערכת</div>
          <div className={styles.message}>{item.message}</div>
        </div> }
        </div>
        )
         })
      }
      </div>
      {/* <div className={styles.buttonSend}>
      <img alt="send"  onClick={(e) => currentRole === 'USER' ? submitMessage() :(editPermission ? submitMessage() : '')} style={{cursor: currentRole === 'USER' ? 'pointer' :(editPermission ? 'pointer' : 'default')}} src={send} className={styles.sendIcon} />
      <input onChange={(e)=>setMessage(e.target.value)} value={message} type="text" className={styles.sendMessage}
        placeholder='רשום הודעה'/>
      </div> */}
      {/* { currentRole !== 'USER' && <div className={styles.buttonSend2}>
      <img alt="send"  onClick={(e) => currentRole === 'USER' ? submitMessage() :(editPermission ? submitMessage() : '')} style={{cursor: currentRole === 'USER' ? 'pointer' :(editPermission ? 'pointer' : 'default')}} src={send} className={styles.sendIcon} />
      <input onKeyDown={(e)=> currentRole === 'USER' ? sendMsgByEnter(e.target.value,e.keyCode) :(editPermission ? sendMsgByEnter(e.target.value,e.keyCode) : '')}  onChange={(e)=>setMessage(e.target.value)} value={message} type="text" className={styles.sendMessage2}
        placeholder='רשום הודעה'/>
        <img alt="meet"  onClick={(e) => currentRole === 'USER' ? '' :(editPermission ? handleClickMeeting() : '')} style={{cursor: currentRole === 'USER' ? 'pointer' :(editPermission ? 'pointer' : 'default'),right:'41px'}} src={meet_desctop} className={styles.request_invite} />
      </div> }
      { currentRole === 'USER' && <div className={styles.buttonSend2}>
      <img alt="send"  onClick={(e) => currentRole === 'USER' ? submitMessage() :(editPermission ? submitMessage() : '')} style={{cursor: currentRole === 'USER' ? 'pointer' :(editPermission ? 'pointer' : 'default')}} src={send} className={styles.sendIcon2} />
      <input onKeyDown={(e)=> currentRole === 'USER' ? sendMsgByEnter(e.target.value,e.keyCode) :(editPermission ? sendMsgByEnter(e.target.value,e.keyCode) : '')}  onChange={(e)=>setMessage(e.target.value)} value={message} type="text" className={styles.sendMessage2}
        placeholder='רשום הודעה'/>
          <img alt="meet" src={meet_desctop} className={styles.request_invite} />
        <Box
                              className={styles.loadContract}
                              component="label"
                            >
                              <img alt="file_upload"  onClick={(e) => currentRole === 'USER' ? sendCustomMessage(1) :(editPermission ? sendCustomMessage(1) : '')} style={{cursor: currentRole === 'USER' ? 'pointer' :(editPermission ? 'pointer' : 'default')}} src={file_upload} className={styles.file_upload} />
                              <input
                                className={styles.uploadImg}
                                id="button-file"
                                name="logoFile"
                                type="file"
                                // accept="application/pdf"
                                onChange={(e) => {
                                  function readFileAsync(index = 0) {
                                    return new Promise((resolve, reject) => {
                                      const file = e.target.files[index];
                                      if (!file) {
                                        return;
                                      }
                                      const reader = new FileReader();

                                      reader.onload = () => {
                                        if (file?.size < 15728640) {
                                          resolve({
                                            id: file?.size || 911,
                                            name: file?.name,
                                            url: `data:${
                                              file.type
                                            };base64,${btoa(reader.result)}`,
                                            file: e.target.files[index],
                                          });
                                        } else {
                                        }
                                      };

                                      reader.onerror = reject;

                                      reader.readAsBinaryString(file);
                                    });
                                  }

                                  readFileAsync().then((item) => {
                                    uploadFile(
                                      item,
                                      item?.file?.name || ""
                                    );
                                    return item;
                                  });
                                  // addImage(await newImg, indexCell)
                                }}
                                hidden
                              />
                            </Box>
        <img alt="meet"  onClick={(e) => currentRole === 'USER' ? sendCustomMessage(1, true) :(editPermission ? sendCustomMessage(1, true) : '')} style={{cursor: currentRole === 'USER' ? 'pointer' :(editPermission ? 'pointer' : 'default')}} src={meet_mobile
        } className={styles.request_invite} />
      </div> } */}
      </div>
      
    <Dialog hideBackdrop={true} sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "1532px",
          boxShadow: 'none',
          overflow: 'hidden',
          borderRadius: '0'
        },
      },
    }} open={openMeeting} fullWidth={true} className={styles.infoDialog}>
      <div className={styles.mainPopup}>
        <div className={styles.close}>
          <svg style={{cursor:'pointer'}}
          xmlns="http://www.w3.org/2000/svg"
          width="25.477"
          height="25.47"
          viewBox="0 0 25.477 25.47"
          onClick={() => handleCloseMeeting()}
          >
          <path
            id="Icon_ionic-ios-close"
            data-name="Icon ionic-ios-close"
            d="M27.042,24.024l9.1-9.1a2.132,2.132,0,1,0-3.015-3.015l-9.1,9.1-9.1-9.1a2.132,2.132,0,1,0-3.015,3.015l9.1,9.1-9.1,9.1a2.132,2.132,0,0,0,3.015,3.015l9.1-9.1,9.1,9.1a2.132,2.132,0,0,0,3.015-3.015Z"
            transform="translate(-11.285 -11.289)"
            fill="#242323"
          />
          </svg>
        </div>
        <div className={styles.blockCalendar}>
          <Calendar onChange={onChange} eventTypeId={eventTypeId}/>
        </div>
      </div>

    </Dialog>
    <Dialog hideBackdrop={true} sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "1532px",
          boxShadow: 'none',
          overflow: 'hidden',
          borderRadius: '0'
        },
      },
    }} open={secondDialog} fullWidth={true} className={styles.infoDialog}>
      <div className={styles.dialogWrapper2}>
          <div className={styles.mainDialog}>
            <div className={styles.contentSecondDialog}>
              <div className={styles.content}>
                <div className={styles.secondTitle}>מזל טוב!! העסקה מול הלקוח {name.userName} נסגרה בהצלחה</div>
                <div className={styles.secondContent}>באפשרותך לבחור האם האירוע חוסם אותך מפעילות באותו היום ואינך תופיע כזמין בתאריך זה, או שתעדיף לקבוע אירוע נוסף באותו תאריך ולהישאר זמין בפיד הספקים לאירוע נוסף באותו תאריך</div>
                <div className={styles.buttonsContent}>
                  {!isPartialDayBlocked && <button onClick={() => {disabledayoncalendar()}} className={styles.leftBtn}>חסום לכל היום</button>}
                  <button onClick={() => {samyDisabledayoncalendar()}} className={styles.rightBtn}>הישאר זמין</button>
                </div>
              </div>
            </div>
          </div>
        </div>

    </Dialog>
    <PopupTest sendAnalitics3={sendAnalitics3} isThreeSteps={true} isOpen={contractSign} isRead={false} setIsOpen={()=>setContractSign(false)} documentId={contractId} setopenPayment={getServicesInfoForPayment}/>
    <Dialog hideBackdrop={true} sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "400px",
          boxShadow: 'none',
          overflow: 'hidden',
          borderRadius: '0'
        },
      },
    }} open={googleInviteInfo} fullWidth={true} className={styles.infoDialog}>
      <div className={styles.mainPopup}>
        <div className={styles.close}>
          <svg style={{cursor:'pointer'}}
          xmlns="http://www.w3.org/2000/svg"
          width="25.477"
          height="25.47"
          viewBox="0 0 25.477 25.47"
          onClick={() => setgoogleInviteInfo(!googleInviteInfo)}
          >
          <path
            id="Icon_ionic-ios-close"
            data-name="Icon ionic-ios-close"
            d="M27.042,24.024l9.1-9.1a2.132,2.132,0,1,0-3.015-3.015l-9.1,9.1-9.1-9.1a2.132,2.132,0,1,0-3.015,3.015l9.1,9.1-9.1,9.1a2.132,2.132,0,0,0,3.015,3.015l9.1-9.1,9.1,9.1a2.132,2.132,0,0,0,3.015-3.015Z"
            transform="translate(-11.285 -11.289)"
            fill="#242323"
          />
          </svg>
        </div>
        <div className={styles.blockCalendar}>
          <div className={styles.infoDialogClose}>
            <div className={styles.infoDialogtitle}>אין לך חשבון גוגל</div>
          </div>
        </div>
      </div>

    </Dialog>
    <Dialog hideBackdrop={true} maxWidth={'lg'} 
    open={lastPopup} 
    fullWidth={true} 
    className={styles.infoDialog}
    >
      <div className={styles.dialog} >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25.477"
          height="25.47"
          viewBox="0 0 25.477 25.47"
          onClick={() => setLastPopup(false)}
          className={classNames(
            styles.closeIcon,
            styles.closeIconDesktop
          )}>
          <path
            id="Icon_ionic-ios-close"
            data-name="Icon ionic-ios-close"
            d="M27.042,24.024l9.1-9.1a2.132,2.132,0,1,0-3.015-3.015l-9.1,9.1-9.1-9.1a2.132,2.132,0,1,0-3.015,3.015l9.1,9.1-9.1,9.1a2.132,2.132,0,0,0,3.015,3.015l9.1-9.1,9.1,9.1a2.132,2.132,0,0,0,3.015-3.015Z"
            transform="translate(-11.285 -11.289)"
            fill="#242323"
          />
        </svg>
        <div>
          <div className={styles.dialogDescription}>החוזה נשלח בהצלחה</div>
          <div className={styles.dialogBtnBlock}><button onClick={() => setLastPopup(false)} className={styles.dialogMsgBtn}>אישור</button></div>
        </div>
      </div>
    </Dialog>
    {/* sale for services from supplier */} 
    <Dialog hideBackdrop={true} maxWidth={'md'} open={openPopup} fullWidth={true} className={styles.infoDialog}>
      <div className={styles.dialog}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25.477"
        height="25.47"
        viewBox="0 0 25.477 25.47"
        onClick={() => contractClosePopup()}
        className={classNames(
          styles.closeIcon,
          styles.closeIconDesktop
        )}>
        <path
          id="Icon_ionic-ios-close"
          data-name="Icon ionic-ios-close"
          d="M27.042,24.024l9.1-9.1a2.132,2.132,0,1,0-3.015-3.015l-9.1,9.1-9.1-9.1a2.132,2.132,0,1,0-3.015,3.015l9.1,9.1-9.1,9.1a2.132,2.132,0,0,0,3.015,3.015l9.1-9.1,9.1,9.1a2.132,2.132,0,0,0,3.015-3.015Z"
          transform="translate(-11.285 -11.289)"
          fill="#242323"
        />
      </svg>
      <div> 


        <form onSubmit={haveDescription? (e)=> {openContractDialog(e); setOpenPopup(false);} : handleSubmit(handleClosePopup)}>
        <div className={styles.supplierDialogTitle}>הצעת מחיר ללקוח:  {contractServices?.data?.userName}</div>
        <div className={styles.supplierContent}>
          <div className={styles.supplierDatePopup}><div>{userName}</div> &nbsp;|&nbsp; {contractServices?.data?.contractDate && <> <div>{convertDate(contractServices.data.contractDate)}</div> &nbsp;|&nbsp; </>} <div>{contractServices.data.eventTypeName}</div></div>
          <div className={styles.supplierInfoPopup}>בקשות הלקוח</div>
          <div className={styles.supplierBlockPopup}>
          {!contractServices.loading && contractServices.data.services.map((item, index) => { 
          return ( <div key={index} className={styles.supplierservicePopup}>
            <div>{item.serviceName}</div> &nbsp;-&nbsp; <div>{numberWithCommas(item.price)}</div>
          </div> ) }) }
          </div>
          <hr className={styles.supplierLinePopup}></hr>
          {!!discount && <div className={styles.supplierSumPopup}>
            <div>{"הנחה"}</div>
            <div className={styles.supplierAllPricePopup}>{numberWithCommas(discount)} &nbsp;<img src={shekelSymbol} alt="shekel" className={styles.shekelSymbol}/> </div>
          </div>}
          <div className={styles.supplierSumPopup}>
            <div>סך הכל</div>
            <div className={styles.supplierAllPricePopup}>{numberWithCommas(allSum)} &nbsp;<img src={shekelSymbol} alt="shekel" className={styles.shekelSymbol}/> </div>
          </div>
          <div className={styles.supplierPriceForm}>
            <div className={styles.supplierPriceFormTitle}>: הנחה</div>&nbsp;
          <span className={styles.countErrorBlock}>
          <input className={classNames({[styles.invalidField]: errors.discount}, styles.supplierPriceFormButton)}  onChange={(e)=>setCurrSum(e.target.value)}  onKeyPress={(event) => {
        if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
        }
      }} type="number" defaultValue={discount} />
     {errors.discount && (
            <p className={styles.countError}>
              {errors.discount.message}
            </p>
          )}
          </span>
          </div>
          <div >
          {haveCheckbox && 
          <FormControlLabel
            label={<Typography className={styles.heeboImportant}>האם יש הערות לחוזה?</Typography>}
            control={
              <Checkbox
                checked={haveDescription}
                style={{ marginRight: '-25px' }} 
                onChange={(event)=>setHaveDescription(event.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          />}
          </div>
          </div>
          <div className={styles.dialogBtnBlock}>
        <button type="submit" style={{fontSize: '1.375rem'}} className={styles.dialogMsgBtn}>{haveDescription? 'התקדם להערות לחוזה' : 'שלח'}</button></div>
      </form>
      </div>
      </div> 
    </Dialog>  
    
     {/* contract from user */}
    <Dialog hideBackdrop={true} maxWidth={'lg'} open={open} fullWidth={true} 
    className={styles.infoDialog}
    PaperProps={{
      sx: {
        m: "0",
        top: "0",
        position: "fixed",
        right: "0",
        minHeight: "100vh",
        // maxHeight: "100vh",
        maxWidth: "637px",
        width: "100%",
      },
    }}
    >
      <div className={styles.dialog} style={{boxShadow: 'none'}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25.477"
        height="25.47"
        viewBox="0 0 25.477 25.47"
        onClick={() => handleClose()}
        className={classNames(
          styles.closeIcon,
          styles.closeIconDesktop
        )}>
        <path
          id="Icon_ionic-ios-close"
          data-name="Icon ionic-ios-close"
          d="M27.042,24.024l9.1-9.1a2.132,2.132,0,1,0-3.015-3.015l-9.1,9.1-9.1-9.1a2.132,2.132,0,1,0-3.015,3.015l9.1,9.1-9.1,9.1a2.132,2.132,0,0,0,3.015,3.015l9.1-9.1,9.1,9.1a2.132,2.132,0,0,0,3.015-3.015Z"
          transform="translate(-11.285 -11.289)"
          fill="#242323"
        />
      </svg>
      {/*firstDialog && <div>
        <div className={styles.dialogTitle}>!חשוב לנו להזכיר</div>
      <div className={styles.dialogDescription}>מרגע קבלת החוזה מידי הספק, עליך להחזיר את החוזה חתום תוך שני
      ימי עסקים .זאת על מנת שנוכל לשמור עבורך את הצעת המחיר והספק המבוקש עבור האירוע שלך. בכל מצב בו לא ישלח חוזה חתום לאחר 2 ימי עסקים, באופן אוטומטי יפוג תוקפו של החוזה המדובר.</div>
      <div className={styles.dialogBtnBlock}><button onClick={()=>{firstStep()}} className={styles.dialogMsgBtn}>שלח לי חוזה</button></div>
        </div> */}
      { firstDialog && <div className={styles.secondStep}>
      <form onSubmit={handleSubmit(secondStep)}>
        <div className={styles.dialogSecondTitle}> ...נבקש שתמלא עוד כמה פרטים נוספים</div>
        <div className={styles.identBlock}>
          <span className={styles.errorBlock}>
          <input  {...register("userIdNumber")} className={classNames({[styles.invalidField]: errors.userIdNumber}, styles.identNumber)} type='number' placeholder='מספר תעודת זהות (כולל ספרת ביקורת)' onChange={(e)=>{
          setInputState1(e.target.value)
          setErrorState1(prev =>{
            prev = resetErrConfig
            if(e.target.value.length === 0) {
              prev.isEmpty = true
            } else if(!/^\d+$/.test(e.target.value)){
              prev.isNumber = false 
            } else if(e.target.value.length === 9){
              prev.isValid = is_israeli_id_number(e.target.value)
            } else if(e.target.value.length > 9){
              prev.isLong = true
            } else if(e.target.value.length < 9){
              prev.isShort = true
            } 
            return prev
          })
          }}/>
          {errorState1.isEmpty && (
            <p className={styles.errorMessage}>
              {'הכנס שם משתמש'}
            </p>
          )}
          {!errorState1.isNumber && (
            <p className={styles.errorMessage}>
              {'רק ספרות'}
            </p>
          )}
          {!errorState1.isValid && (
            <p className={styles.errorMessage}>
              {'מספר זהות אינו תקין'}
            </p>
          )}
          {errorState1.isLong && (
            <p className={styles.errorMessage}>
              {'מקסימום 9 ספרות'}
            </p>
          )}
          {errorState1.isShort && (
            <p className={styles.errorMessage}>
              {'לפחות 9 אותיות'}
            </p>
          )}
          </span>
        </div>
        {secondId && <div className={styles.identBlock}>
          <span className={styles.errorBlock}>
          <input {...register("additionalUserIdNumber")} className={classNames({[styles.invalidField]: errors.additionalUserIdNumber}, styles.identNumber)}  type='number' placeholder='מספר תעודת זהות (כולל ספרת ביקורת)' onChange={(e)=>{
            setInputState2(e.target.value)
            setErrorState2(prev => {
              prev = resetErrConfig 
              if(e.target.value.length === 0) {
                prev.isEmpty = true
              } else if(!/^\d+$/.test(e.target.value)){
                prev.isNumber = false 
              }else if(e.target.value.length === 9){
                prev.isValid = is_israeli_id_number(e.target.value)
              } else if(e.target.value.length > 9){
                prev.isLong = true
              } else if(e.target.value.length < 9){
                prev.isShort = true
              } 
              return prev
            })
          }}/>
          {secondId && errorState2.isEmpty && (
            <p className={styles.errorMessage}>
              {'הכנס שם משתמש'}
            </p>
          )}
          {secondId && !errorState2.isNumber && (
            <p className={styles.errorMessage}>
              {'רק ספרות'}
            </p>
          )}
          {secondId && !errorState2.isValid && (
            <p className={styles.errorMessage}>
              {'מספר זהות אינו תקין'}
            </p>
          )}
          {secondId && errorState2.isLong && (
            <p className={styles.errorMessage}>
              {'מקסימום 9 ספרות'}
            </p>
          )}
          {secondId && errorState2.isShort && (
            <p className={styles.errorMessage}>
              {'לפחות 9 אותיות'}
            </p>
          )}

          </span>
        </div> }
        {!secondId && <div onClick={()=> setsecondId(true) } className={styles.identLink}>הוסף מספר תעודת זהות נוסף</div> }
        {secondId && <div onClick={()=> setsecondId(false) } className={styles.identLink}>הסר את מספר הזיהוי השני</div> }
        <div className={styles.address}>כתובת מגורים</div>
        <div className={styles.addressBlock}>
        <span className={styles.adressInputs} style={{marginLeft: '1rem'}}>
        <input style={{width:'100%'}} {...register("city")} className={classNames({[styles.invalidField]: errors.city}, styles.city)} type='text' placeholder='שם העיר' />
          {errors.city && (
          <p className={styles.errorMessageBlocks}>
            {errors.city.message}
          </p>
          )}
          </span>
        <span className={styles.adressInputs}>
        <input style={{width:'100%'}} {...register("address")} className={classNames({[styles.invalidField]: errors.address}, styles.street)} type='text' placeholder='שם הרחוב ומספר בניין' />
          {errors.address && (
          <p className={styles.errorMessageLeft}>
            {errors.address.message}
          </p>
          )}
        </span>
        </div>
        {secondAddress && <div className={styles.addressBlock}>
        <span className={styles.adressInputs}>
        <input style={{width:'100%'}} {...register("additionalCity")}  className={classNames({[styles.invalidField]: errors.additionalCity}, styles.city)}  type='text' placeholder='שם העיר' />
          {errors.additionalCity && (
          <p className={styles.errorMessageBlocks}>
            {errors.additionalCity.message}
          </p>
          )}
        </span>
        <span className={styles.adressInputs} style={{marginRight: '1rem'}}>
        <input style={{width:'100%'}} {...register("additionalAddress")} className={classNames({[styles.invalidField]: errors.additionalAddress}, styles.street)}  type='text' placeholder='שם הרחוב ומספר בניין' />
          {errors.additionalAddress && (
          <p className={styles.errorMessageLeft}>
            {errors.additionalAddress.message}
          </p>
          )}
        </span>
        </div> }
        {!secondAddress && <div onClick={()=> setAddress(true) } className={styles.identLink}>הוסף כתובת מגורים נוספת</div> }
        {secondAddress && <div onClick={()=> setAddress(false) } className={styles.identLink}>הסר את הכתובת השנייה</div> }
        <div className={styles.dialogBtnBlock} style={{justifyContent: "flex-start"}}>
          <button type="submit" className={styles.dialogMsgBtn} onClick={() => {
          setErrorState1(prev =>{
            return{...prev , isEmpty: inputState1 === '' }
          })
          setErrorState2(prev =>{
            return{...prev , isEmpty:  inputState2 === '' && secondId }
          })
          }}>אפשר להמשיך</button></div>
          <div className={styles.tabProgress}>
            <span style={{borderColor:"#405F6A"}}></span>
            <span style={{borderColor:"rgba(112, 112, 112, .17)"}}></span>
            <span style={{borderColor:"rgba(112, 112, 112, .17)"}}></span>
          </div>
      </form>
      </div> }
      
      {/* thirdDialog && <div>
        <div className={styles.dialogTitle}>הודעת תזכורת</div>
      <div className={styles.dialogDescription}> נותרו 48 שעות לחתימת החוזה {JSON.parse(auth?.user) ? JSON.parse(auth?.user).name : ''} אם במידה ולא ישלח החוזה במשך זמן זה יבוטל החוזה באופן אוטומטי</div>
      <div className={styles.dialogBtnBlock}><button onClick={()=>{handleClose()}} className={styles.dialogMsgBtn}>הבנתי, אפשר להמשיך</button></div>
          </div> */}
      </div> 
    </Dialog>
    <Dialog hideBackdrop={true} sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "400px",
          boxShadow: 'none',
          overflow: 'hidden',
          borderRadius: '0'
        },
      },
    }} open={statusUnPaid} fullWidth={true} className={styles.infoDialog}>
      <div className={styles.mainPopup}>
        <div className={styles.close}>
          <svg style={{cursor:'pointer'}}
          xmlns="http://www.w3.org/2000/svg"
          width="25.477"
          height="25.47"
          viewBox="0 0 25.477 25.47"
          onClick={() => setstatusUnPaid(!statusUnPaid)}
          >
          <path
            id="Icon_ionic-ios-close"
            data-name="Icon ionic-ios-close"
            d="M27.042,24.024l9.1-9.1a2.132,2.132,0,1,0-3.015-3.015l-9.1,9.1-9.1-9.1a2.132,2.132,0,1,0-3.015,3.015l9.1,9.1-9.1,9.1a2.132,2.132,0,0,0,3.015,3.015l9.1-9.1,9.1,9.1a2.132,2.132,0,0,0,3.015-3.015Z"
            transform="translate(-11.285 -11.289)"
            fill="#242323"
          />
          </svg>
        </div>
        <div className={styles.blockCalendar}>
          <div className={styles.infoDialogClose}>
            <div className={styles.infoDialogtitle}>התשלום נכשל נסה מאוחר יותר</div>
          </div>
        </div>
      </div>

    </Dialog>
    </>
    : <>
      <Payment isOpen={openPayment} value={name?.supName} closePopup={closePaymentPopup} />
      <div className={styles.paymentContent}>
        <div className={styles.paymentRight}>
          <div className={styles.paymentMobile}>
            <div className={styles.paymentMobileContent} onClick={() => {getServicesInfoForPayment();}}>
              <div style={{cursor:'pointer'}} onClick={() => {setIsOpenDropDown(!isOpenDropDown);}} >פירוט העסקה</div>
              <img onClick={() => {setIsOpenDropDown(!isOpenDropDown);}} alt="arrow" style={{transform: isOpenDropDown ? "" : "rotate(180deg)",cursor:'pointer'}} src={arrowMobile} />
            </div>
          </div>
          {isOpenDropDown && <div>
            <hr className={styles.paymentLineTop}></hr>
          <div>
            <div className={styles.paymentTitle}>{allInformation.supplierName} | {allInformation.categoryName}</div>
            <hr className={styles.paymentLine}></hr>
            <div className={styles.paymentSecondTitle}>הצעת מחיר עדכנית לתאריך {convertDate(allInformation.creationDate)}</div>
            <div className={styles.paymentBlock}>
            {!paymentServices.loading && allInformation.services.map((item,index)=> {
              return(
              <div className={styles.paymentserviceBlock} key={index}>{item.serviceName} - {numberWithCommas(item.price)}</div>
              )
            })
            }
            </div>
            <hr className={styles.paymentLineEnd}></hr>
            <div className={styles.paymentPrice}>{'סך הכל'} - {numberWithCommas(allInformation.totalAmount)} <img alt="shekel" src={shekel} /></div>
            {allInformation.discountPrice !== allInformation.totalAmount && <div className={styles.paymentPriceAfterDiscount}>{'מחיר לאחר הנחה'} - {allInformation.discountPrice} <img alt="shekel" src={shekel} /></div>}
            <div className={styles.paymentDiscountBlock}>
              <div className={styles.paymentDiscount}>- {'סך תשלום מקדמה'} {numberWithCommas(allInformation.prepayment)} <img alt="shekel" src={shekel} /></div>
              <div className={styles.paymentDiscountPrice}>{allInformation.prepaymentPercentage}{'%'} מכלל העסקה</div>
            </div>
          </div>
          </div>}
        </div>
        <div className={styles.paymentLeft}>
          {!isPaySuccess && <div className={styles.absolutLoader}>
              <div className={styles.ldsDualRing}></div>
          </div>}
          <div className={styles.iframe}>
            <iframe style={{"z-index": "100"}} title="tranzila-iframe" 
            id="tranzila-iframe"  width="100%"
            height="100%" 
            src={`https://direct.tranzila.com/${process.env.REACT_APP_TRANZILA}/iframenew.php?hidesum=1&sum=${allInformation.prepayment}&currency=1&cred_type=1&lang=il&userId=${userId}&contractId=${allInformation.id}&tranmode=AK&nologo=1&email=${allInformation.userEmail}&contact=${allInformation.userName}&pdesc=${allInformation.pdesc}`}  
            sandbox="allow-modals allow-forms allow-popups allow-scripts"
            frameBorder="0">
              <input type="hidden" name="email" value={allInformation.userEmail}/>
              <input type="hidden" name="contact" value={allInformation.userName}/>
              <input type="hidden" name="pdesc" value={allInformation.pdesc}/>
            </iframe>
          </div>
        </div>
      </div>
    </>
    }
    </>
    );
  }   
export default Chat;